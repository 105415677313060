import { createRouter, createWebHistory } from "vue-router"
import testRoute from "./test_routes"
import forgotUsernameRoutes from "./forgot_username_routes"
import accountRecoveryRoutes from "./account_recovery_routes"
import HomeGetter from "../store/modules/enroll_home"
import TokenGetter from "../store/modules/mfa_data"
import EnrollHome from "../views/Home.vue"
import ProfileInfo from "../views/ProfileInfo.vue"
import CreateUserID from "../views/CreateUserID.vue"
import EnhancedSecurity from "../views/EnhancedSecurity.vue"
import VisitBranch from "../views/VisitBranch.vue"
import AlreadyHaveAccount from "../views/AlreadyHaveAccount.vue"
import AccountConfrimation from "../views/AccountConfirmation.vue"
import SendCodeToPhone from "../views/MFA/SendCodetoPhone.vue"
import SendCodeToEmail from "../views/MFA/SendCodeToEmail.vue"
import SomethingWentWrong from "../views/MFA/SomethingWentWrong.vue"
import PageNoFound from "../views/PageNotFound.vue"
import MaintenancePage from "../views/MaintenacePage.vue"
import BusinessAccount from "../views/BusinessAccount.vue"
import OAOConfirmation from "../views/OAOConfirmation.vue"
import FundingEnrollmentRoutes from "./funding-enrollments"

let routes = [
  {
    path: "/",
    redirect: "/enroll/",
  },
  {
    path: "/enroll/",
    name: "enroll-home",
    component: EnrollHome,
  },
  {
    path: "/profileinfo",
    name: "profile-info",
    component: ProfileInfo,
    beforeEnter: (to, from, next) => {
      if (HomeGetter.state.applicant.lastName === "") {
        next({
          path: "/",
        })
      } else {
        next()
      }
    },
  },
  {
    path: "/createuserid",
    name: "create-userID",
    component: CreateUserID,
    beforeEnter: (to, from, next) => {
      if (TokenGetter.state.token === "") {
        next({
          path: "/",
        })
      } else {
        next()
      }
    },
  },
  {
    path: "/enhancedsecurity",
    name: "enhanced-security",
    component: EnhancedSecurity,
    beforeEnter: (to, from, next) => {
      if (HomeGetter.state.applicant.lastName === "") {
        next({
          path: "/",
        })
      } else {
        next()
      }
    },
  },
  {
    path: "/visitbranch",
    name: "visit-branch",
    component: VisitBranch,
    beforeRouteLeave(to, from, next) {
      if (TokenGetter.state.token === "") {
        next({
          path: "/",
        })
      } else {
        next()
      }
    },
  },
  {
    path: "/businessaccount",
    name: "business-account",
    component: BusinessAccount,
  },
  {
    path: "/accountexists",
    name: "account-exists",
    component: AlreadyHaveAccount,
  },
  {
    path: "/sendcodetophone",
    name: "send-code-to-phone",
    component: SendCodeToPhone,
    beforeEnter: (to, from, next) => {
      if (HomeGetter.state.applicant.lastName === "") {
        next({
          path: "/",
        })
      } else {
        next()
      }
    },
  },
  {
    path: "/sendcodetoemail",
    name: "send-code-to-email",
    component: SendCodeToEmail,
    beforeEnter: (to, from, next) => {
      if (HomeGetter.state.applicant.lastName === "") {
        next({
          path: "/",
        })
      } else {
        next()
      }
    },
  },
  {
    path: "/somethingwentwrong",
    name: "something-went-wrong",
    component: SomethingWentWrong,
  },
  {
    path: "/maintenance",
    name: "maintenance-page",
    component: MaintenancePage,
  },
  {
    path: "/accountconfirmation",
    name: "account-confirmation",
    component: AccountConfrimation,
    beforeEnter: (to, from, next) => {
      if (HomeGetter.state.applicant.lastName === "") {
        next({
          path: "/",
        })
      } else {
        next()
      }
    },
    beforeRouteLeave(to, from, next) {
      if (!HomeGetter.state.applicant.lastName === "") {
        next({
          path: "/",
        })
      } else {
        next()
      }
    },
  },
  {
    path: "/accountconfirmation/oao",
    name: "account-confirmation-oao",
    component: OAOConfirmation,
  },
]

routes = accountRecoveryRoutes.loadRoutes(routes)
routes = forgotUsernameRoutes.loadRoutes(routes)
routes = testRoute.loadRoutes(routes)
routes = FundingEnrollmentRoutes.loadRoutes(routes)

routes.push({
  path: "/:pathMatch(.*)*",
  name: "Page Not Found",
  component: PageNoFound,
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
