import axios from 'axios'

const Retail_BASE_URL = process.env.VUE_APP_API_URL

export default {

  usernameController: null,
  fundingTokenController: null,

  getUserInfo (payload) {
    console.log('---UserData Payload ---')
    console.log(payload)
    const endpoint = Retail_BASE_URL + '/enroll'
    return axios.post(endpoint, payload).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  },
  putContactInfo (applicant_id, payload) {
    const endpoint = Retail_BASE_URL + '/enroll/' + applicant_id + '/contactinformation'
    return axios.put(endpoint, payload).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  },

  requestOTPCode (payload) {
    console.log('--- MFA Methods payload---')
    console.log(payload)
    const endpoint = Retail_BASE_URL + '/enroll/' + payload.id + '/mfa?method=' + payload.methods +'&email='+ payload.email + '&phone=' + payload.phone
    // const prarams = {
    //   params: {     
    //   method: payload.methods,
    //   // email: payload.email,
    //   // phone: payload.phone,
    //   }
    // }
    return axios.get(endpoint, payload).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  },
  verifyOTPCode (payload) {
    console.log('---verifyMFA---')
    console.log(payload)
    const endpoint = Retail_BASE_URL + '/enroll/' + payload.id + '/mfa'
    return axios.put(endpoint, payload).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  },
  submitUserInfo (payload, token) {
    console.log('---Submit UserInfo Payload ---')
    console.log(payload)
    const endpoint = Retail_BASE_URL + '/enroll/' + payload.id + '/submit'
    return axios.post(endpoint, payload, { headers: { Authorization: 'Bearer ' + token }}).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  },
  addServiceAccount (token, id) {
    console.log('---Add Service Account Payload ---')
    console.log(token, id)
    const endpoint = Retail_BASE_URL + '/enroll/' + id + '/chargeaccount'
    return axios.put(endpoint, null, { headers: { Authorization: 'Bearer ' + token }}).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  },
  checkUserName (username, id, token) {
    console.log('---Check Username Payload ---')

    if(this.usernameController) this.usernameController.abort()
    this.usernameController = new AbortController()
    var requestData = {
      signal: this.usernameController.signal,
      headers: {
          Authorization: 'Bearer ' + token
      }
    }
    const endpoint = Retail_BASE_URL + '/username/exists?username=' + username + '&id=' + id

    return axios.get(endpoint, requestData ).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  }, 
  getFundingSSOToken (id) {
    console.log('--- getting funding token ---')

    if (this.fundingTokenController) this.fundingTokenController.abort()
    this.fundingTokenController = new AbortController()
    var requestData = {
      signal: this.fundingTokenController.signal,
    }
    const endpoint = Retail_BASE_URL + `/enrollments/sso/${id}`

    return axios.get(endpoint, requestData ).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  }, 
  postFundingSSOEnroll (enrollmentID, user, pass, token) {
    const payload = {
      username: user,
      password: pass
    }
    const endpoint = Retail_BASE_URL + `/enrollments/sso/${enrollmentID}/enroll`
    return axios.post(endpoint, payload, { headers: { Authorization: 'Bearer ' + token }}).then(response => {
      return response
    }).catch(error => {
      return error.response
    })
  }
}
