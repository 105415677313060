<template>
  <div class="page-background">
    <FHBHeader></FHBHeader>
    <div class="bg-container">
      <div class="bg-orange">
        <div class="svg-container">
          <svg
            aria-hidden="true"
            height="100%"
            width="100%"
            preserveAspectRatio="none"
            viewBox="0 0 100 10"
            class="coh-inline-element"
          >
            <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
          </svg>
        </div>
      </div>
    </div>
    <div class="page-not-found-content-container form-container smaller-header" id="page-not-found">
      <div class="not-found-overlay-content">
        <div class="header"></div>
        <img
          class="icon"
          srcset="@/assets/images/PageNotFound.png 1x, @/assets/images/PageNotFound-2x.png 2x"
          src="@/assets/images/PageNotFound-2x.png"
        />
        <div class="msg">Page Not Found</div>
        <div>
          The page you're looking for could not be found. Please visit our
          <a class="home-page-link" href="https://www.fhb.com" data-test="not-found-login-btn"
            >home page</a
          >
          for more information.
        </div>
      </div>
    </div>
    <FHBFooter class="fhb-footer"></FHBFooter>
  </div>
</template>

<script>
import FHBFooter from "@/components/footer"
import FHBHeader from "@/components/header"

export default {
  name: "PageNotFound",
  components: { FHBFooter, FHBHeader },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.header {
  margin: auto;
  padding: 0 0 2rem;
}

.icon {
  display: block;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 102px;
}

.msg {
  margin: 0.5em auto;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
}

.page-not-found-content-container {
  align-content: center;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  min-height: 58vh;
  justify-content: center;
  padding: 0 2em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.not-found-overlay-content {
  height: auto;
  margin: 2em auto;
  padding: 2em;
  position: relative;
  text-align: center;

  @include media-breakpoint-up(sm) {
    background: $white;
    border-radius: 8px;
    box-shadow: rgba(68, 69, 71, 0.2) 0px 10px 24px;
    max-width: 500px;
    width: calc(100% - 4em);
  }

  @include media-breakpoint-up(lg) {
    max-width: 700px;
  }
}

.page-background {
  width: 100%;
  text-align: center;
  height: 100%;
}

@media (min-height: 1160px) {
  .fhb-footer {
    width: 100%;
    bottom: 0px;
    position: fixed;
  }
}

.home-page-link {
  text-decoration: none;
  color: #a1252c;
  font-weight: bold;
}

.home-page-link:hover,
.home-page-link:active {
  text-decoration: underline;
}
</style>
