<template>
  <div id="app">
    <div
      role="complementary"
      aria-labelledby="skiptomain"
      class="skip-link"
      v-if="
        this.$route.name !== 'create-userID' &&
        this.$route.name !== 'account-confirmation' &&
        this.$route.name !== 'username-info' &&
        this.$route.name !== 'account-unlocked'
      "
    >
      <a id="skiptomain" href="#main" tabindex="0" class="element-invisible element-focusable">
        Skip to main content
      </a>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  created() {
    // for tethered login (funding)
    let loginScript = document.createElement("script")
    loginScript.setAttribute("type", "text/javascript")
    loginScript.setAttribute("src", "https://cds-sdkcfg.onlineaccess1.com/common.js")
    document.head.insertBefore(loginScript, document.head.firstChild)
  },
}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.svg-container {
  bottom: -8px;
  position: absolute;
  width: 100%;
}

svg polygon {
  fill: $white;
}

.bg-container {
  width: 100%;
  background-color: $bgColor;
  text-align: center;
  // height: auto;

  h1,
  h2 {
    margin: auto;
    max-width: calc(100% - 2em);
    position: relative;
    z-index: 20;

    @include media-breakpoint-up(md) {
      max-width: calc(100% - 4em);
    }
  }

  h1 {
    padding-top: 1em;
  }

  h2 {
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 0.35em;
    // padding-bottom: 1.5em;
    padding-bottom: 1em;
  }
}

@include media-breakpoint-up(md) {
  .bg-container {
    left: 0;
    position: relative;
  }
}

@include media-breakpoint-down(sm) {
  .bg-container .bg-orange {
    background-color: $white;
    height: auto;
    padding-top: 1.2em;
  }

  .bg-container .bg-orange .svg-container {
    display: none !important;
  }

  .bg-container h1 {
    font-size: 1.8rem;
  }
}

@include media-breakpoint-up(sm) {
  .bg-container .bg-orange {
    background-color: $orangeFaint;
    background-image: url("./assets/images/orange@1x.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 1200px;
    height: 380px;
    padding-bottom: 70px;
    padding-top: 25px;
    position: relative;
    width: 100%;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

a {
  word-wrap: break-word;
}

#app {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  line-height: 1.5;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

a,
a:visited,
a:link,
button {
  color: $black;
}

// button {
//   text-transform: uppercase;
// }

.skip-link a {
  color: $redDark;
}

.tel {
  white-space: nowrap;
}

select {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}

// .container {
//   @include media-breakpoint-up(lg) {
//     margin-left: auto;
//     margin-right: auto;
//     max-width: 860px;
//   }
//   @include media-breakpoint-down(lg) {
//     margin-left: 2em;
//     margin-right: 2em;
//   }
//   @include media-breakpoint-down(sm) {
//     margin-left: 1.5rem;
//     margin-right: 1.5em;
//   }
//   @include media-breakpoint-up(xxl) {
//     margin-left: auto;
//     margin-right: auto;
//     max-width: 1200px;
//   }
// }

h1 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 35px;
  margin-bottom: 0px;
}

h2 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
}

h3 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}

input {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}

button,
.swal2-popup {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: "CircularXXWeb-Book";
  src: url("./assets/fonts/CircularXXWeb-Book.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Book";
  src: url("./assets/fonts/CircularXXWeb-Book.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXTT-Bold";
  src: url("./assets/fonts/CircularXXTT-Bold.ttf") format("woff2");
}

@font-face {
  font-family: "CircularXXTT-Book";
  src: url("./assets/fonts/CircularXXTT-Book.ttf") format("woff2");
}

// .env-banner {
//   display: block;
//   position: absolute;
//   height: 25px;
//   width: 100%;
//   color: $white;
//   top: 0;
//   left: 0;
//   z-index: 100;
// }
// .env-localhost {
//   background-color: rgb(37, 161, 66, 0.5);
// }
// .env-dev {
//   color: black;
//   background-color: rgb(255, 252, 55, 0.5);
// }
// .env-staging {
//   background-color: rgb(161, 37, 37, 0.5);
// }

.fhb-red {
  color: $redSup;
}

.hr {
  border-top: 1px solid $gray;
  height: 1px;
  margin: 2em 0;
}

// .btn-container {
//   align-items: center;
//   column-gap: 2em;
//   display: grid;
//   grid-template-columns: auto auto;
//   justify-content: center;
//   text-align: center;
// }
// .back-btn {
//   align-items: center;
//   background-color: $white;
//   color: $black;
//   display: flex;
//   width: 63px;
//   height: 40px;
//   justify-content: center;
//   border: 1px solid $grayDark;
//   border-radius: 20px;
//   grid-column: 1 / span 1;
//   grid-row: 1 / span 1;

//   &:hover,
//   &:focus,
//   &:active {
//     background-color: $grayLight;
//     cursor: pointer;
//     transition: background-color 300ms ease, 300ms ease opacity;
//   }
// }
// .back-icon {
//   height: auto;
//   width: 8px;
// }
// .goto-fhb-btn {
//   align-items: center;
//   background-color: $white;
//   border: 1px solid $grayDark;
//   border-radius: 20px;
//   color: $black;
//   display: flex;
//   height: 40px;
//   justify-content: center;
//   letter-spacing: 0.05em;
//   text-transform: uppercase;
//   width: 209px;

//   &:hover,
//   &:active,
//   &:focus {
//     background-color: $grayLight;
//     cursor: pointer;
//     transition: background-color 300ms ease, 300ms ease opacity;
//   }
// }
// .goto-fhb-text {
//   color: $black;
//   font-size: 0.8rem;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 1.5;
//   letter-spacing: 0.05em;
//   position: relative;
//   text-align: center;
// }
.continue-btn {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border: none;
  border-radius: 20px;
  color: $black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.029em;
  line-height: 1.5;
  text-align: center;
  width: 170px;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.default-btn {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border-radius: 20px;
  color: $black;
  width: 140px;
  height: 40px;
  justify-content: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.029em;
  margin: auto;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.continue-text {
  font-weight: 700;
  letter-spacing: 0.05em;
  position: relative;
  text-transform: uppercase;
}

.v-spacer-60 {
  height: 60px;
}

.v-spacer-50 {
  height: 50px;
}

.v-spacer-40 {
  height: 40px;
}

.v-spacer-30 {
  height: 30px;
}

.v-spacer-20 {
  height: 20px;
}

.v-spacer-15 {
  height: 15px;
}

.v-spacer-10 {
  height: 10px;
}

.v-spacer-5 {
  height: 5px;
}

.separator {
  color: $gray;
  font-weight: 300;
}

.field-group {
  position: relative;
}

.field-group + .field-group {
  @include media-breakpoint-down(lg) {
    margin-top: 2em;
  }
}

// .section-title {
//   font-size: 2.2rem;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 1.5;
//   margin: 0;
//   text-align: left;

.icon-validation {
  bottom: 2.6em;
  height: 0;
  padding-right: 1.2em;
  padding-top: 0.47em;
  position: relative;
  text-align: right;
  z-index: 2;

  img {
    height: auto;
    width: 24px;
  }

  // @include media-breakpoint-down(sm) {
  //   bottom: 2em;
  // }
}

.icon-validation,
.ll-textfield,
.tf-note {
  width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 100%;
    width: 548px;
  }
}

.grid-container,
.ll-row {
  .icon-validation,
  .ll-textfield,
  .tf-note {
    @include media-breakpoint-up(lg) {
      max-width: unset;
    }
  }
}

.ll-textfield,
.ll-textfield-error {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}

.ll-textfield {
  background-color: $white;
  border: 1px solid $grayMid;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
}

// .ll-textfield-money {
//   padding-left: 26px;
// }
// .ll-prefix-wrap {
//   position: relative;
// }
// .tf-dollar-prefix {
//   align-items: center;
//   height: 40px;
//   position: absolute;
//   display: flex;
//   left: 1em;
//   bottom: 0;
//   width: 12px;

//   @include media-breakpoint-up(sm) {
//     height: 56px;
//   }
// }
.ll-select-error,
.ll-select {
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 42px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}

.ll-select {
  background-color: $white;
  border: 1px solid $grayMid;
}

.ll-select-error {
  background: $white;
  border: 1px solid $red;
}

.ll-item {
  position: relative;

  > input[type="checkbox"] {
    margin-left: 1px;
  }
}

.ll-item + .ll-item {
  @include media-breakpoint-down(sm) {
    margin-top: 1.5em;
  }
}

.page-error-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  background-color: $white;
  color: $red;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5;
  text-align: left;
  border: 1px solid $red;
  display: grid;
  grid-template-columns: 50px auto;
}

.page-error-icon-container {
  background-color: $redFaint;
  text-align: center;
}

.page-error-icon {
  height: auto;
  padding-top: 1.2em;
  width: 15px;
}

.page-error-msg {
  padding: 1em;
}

.dump {
  text-align: left;
}

.tf-note {
  margin-top: 4px;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  a {
    &:hover,
    &:active,
    &:focus {
      color: $redDark;
    }
  }
}

.footnote {
  background-color: $orangeFocus;
  color: $black;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  grid-gap: 0.25em;
  line-height: 1.5;
  margin-top: 30px;
  padding: 25px;
}

.disclosure-bottom strong {
  font-size: 0.95rem;
  font-weight: 700;
  letter-spacing: 0.029em;
}

.disclosure-bottom {
  border: 1px solid $grayMid;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  border-radius: 3px;
  padding: 15px;

  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.form-error-msg {
  display: grid;
  grid-column-gap: 0.35em;
  grid-template-columns: 15px auto;
  margin-top: 5px;

  .form-error-icon {
    height: auto;
    margin-top: 0.1em;
    width: 15px;
  }
}

.form-error-txt {
  display: inline-block;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: $redDark;
}

// .toggle-btn-container {
//   align-items: stretch;
//   column-gap: 1em;
//   display: flex;
//   flex-wrap: wrap;
//   font-size: 1rem;
//   margin-bottom: 2px;
//   row-gap: 1em;
// }
.toggle-btn-container > * {
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px;
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
}

.sys-err-msg {
  border: 1px solid $red;
  color: $red;
  padding: 0.75em 1.5em;
  text-align: center;
}

.sys-err-icon {
  margin-right: 10px;
}

.sys-err-list {
  display: inline-block;
  text-align: left;
}

.txt-bold {
  font-weight: 800;
}

body img[src*="turn"] {
  bottom: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
}

.all-caps {
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.header-logo,
.submitted-logo {
  height: auto;

  @include media-breakpoint-up(md) {
    padding-bottom: 12px;
    width: 225px;
  }

  @include media-breakpoint-down(md) {
    width: 150px;
  }
}

.page-bg {
  background-color: $orangeFaint;
  height: 100%;
  min-height: 100vh;
  text-align: center;
}

.content-container {
  align-content: center;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0 2em;
  width: 100%;
}
.hide-form {
  margin-top: 100px;
  justify-content: flex-start;
}

.overlay-content {
  height: auto;
  margin: 2em auto;
  padding: 2em;
  position: relative;
  text-align: center;

  @include media-breakpoint-up(sm) {
    background: $white;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: 500px;
    width: calc(100% - 4em);
  }

  @include media-breakpoint-up(lg) {
    max-width: 700px;
  }
}

.submitted-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.submitted {
  height: auto;
  margin: 2em auto;
  padding: 2em;
  text-align: center;

  @include media-breakpoint-up(sm) {
    background: $white;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: 500px;
    width: calc(100% - 4em);
  }

  @include media-breakpoint-up(lg) {
    max-width: 700px;
    padding: 2em 4em;
  }
}

.overlay-splash {
  margin: 2em 0 0.5em;
}

.overlay-title {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}

.overlay-subtitle {
  margin-top: 0.25em;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}

.overlay-subtitle {
  margin-top: 20px;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.011em;
  text-align: center;
}

.overlay-body {
  display: inline-block;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-top: 15px;
}

.return-fhb-link-btn {
  background-color: $white;
  border-radius: 20px;
  display: block;
  min-height: 40px;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin: auto;
  padding: 0.8em 1.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 240px;
  border: 1px solid $grayDark;
  box-sizing: border-box;
  border-radius: 20px;

  &:visited {
    color: inherit;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.gradiant-bar {
  height: 20px;
  background: linear-gradient(180deg, $grayVlight 0%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: multiply;
  position: relative;
  z-index: 80;
}

.processing {
  background-color: $grayLight !important;
  border-color: $grayLight !important;
  color: $grayDark !important;
}

.processing:hover {
  cursor: default !important;
  background-color: $grayLight !important;
}

.confirm-btn {
  outline: 2px dotted $red;
}

.swal2-image {
  margin-top: 3em;
}

.swal2-actions {
  padding-bottom: 1.5em;
}

.swal2-popup {
  position: relative;
}

.swal2-popup button.swal2-styled.swal2-confirm {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border: none;
  border-radius: 20px;
  color: $black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.029em;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  width: 220px;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: unset;
}

.swal2-confirm {
  font-weight: 700;
}

.swal2-actions.swal2-loading {
  display: none;
  /*right: 186px;
  position: absolute;
  top: 49px;*/
}

.swal2-loader {
  border-color: $white transparent $white transparent;
  height: 1.8em;
  width: 1.8em;
}

.swal2-styled.swal2-confirm:focus {
  background-color: $yellowHover;
  box-shadow: 0 0 0 3px rgba(240, 171, 54, 0.3);
}

form {
  background-color: $white;
  text-align: left;
  max-width: 100%;
  padding: 2em 2.5em 2.2em;
  margin: auto;
  position: relative;
  z-index: 40;

  @include media-breakpoint-up(sm) {
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: calc(100% - 4em);
  }

  @include media-breakpoint-up(md) {
    max-width: 620px;
  }
}

.form-container {
  margin-top: 0;

  @include media-breakpoint-up(sm) {
    margin-top: -160px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -190px;
  }
}

.smaller-header.form-container {
  margin-top: 0;

  @include media-breakpoint-up(sm) {
    margin-top: -240px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -250px;
  }
}

.emptySpace {
  position: relative;
  height: 35.6px;
}

@include media-breakpoint-down(sm) {
  .bg-text {
    padding-top: 20px;
  }
}

label,
.label {
  color: $black;
  display: block;
  font-size: 1.1rem;
  margin: 1em 0 0.2em;
  font-weight: bold;
}

.header-container:after {
  content: "";
  display: block;
  height: 20px;
  background: linear-gradient(180deg, $grayVlight 0%, rgba(255, 255, 255, 0) 100%);
  bottom: -20px;
  mix-blend-mode: multiply;
  position: absolute;
  width: 100%;
  z-index: 80;
}

.back-btn {
  align-items: center;
  background-color: $white;
  color: $black;
  display: flex;
  width: 63px;
  height: 40px;
  justify-content: center;
  border: 1px solid $grayDark;
  border-radius: 20px;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.back-icon {
  height: auto;
  width: 8px;
}

.overlay-info {
  border-radius: 8px;
  height: auto;
  margin: auto;
  padding: 45px;
  position: relative;
  text-align: center;
  width: 100%;

  @include media-breakpoint-up(sm) {
    background: $white;
    max-width: 500px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: rgba(0, 0, 0, 0.15);
    width: 100%;
  }
}

.noti-container {
  align-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

a:hover,
a:active {
  color: $redDark;
}
.spinner {
  width: 25px;
  height: 25px;
  margin-right: 3px;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>
