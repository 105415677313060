// to organize routes
import AccountRecovery from "../views/accounts/recovery/AccountRecovery.vue"
import ProcessingAccount from "../views/accounts/recovery/ProcessingAccount.vue"
import AccountUnlocked from "../views/accounts/recovery/AccountUnlocked.vue"
import AccountInvalid from "../views/accounts/recovery/InvalidRecovery.vue"

const accountRecoveryRoutes = {
  loadRoutes(routes) {
    routes.push(
      {
        path: "/accountrecovery",
        name: "account-recovery",
        component: AccountRecovery,
      },
      {
        path: "/accountrecovery/processingaccount",
        name: "processing-account",
        component: ProcessingAccount,
      },
      {
        path: "/accountrecovery/:id",
        name: "account-unlocked",
        component: AccountUnlocked,
      },
      {
        path: "/invalidrecovery",
        name: "account-invalid",
        component: AccountInvalid,
      }
    )
    return routes
  },
}

export default accountRecoveryRoutes
