// to organize routes
import ForgotUsername from "../views/username/forgotten/ForgotUsername.vue"
import ProcessingUsername from "../views/username/forgotten/ProcessingUsername.vue"
import InvalidUsername from "../views/username/forgotten/InvalidUsername.vue"
import UsernameInfo from "../views/username/forgotten/UsernameInfo.vue"

const forgotUsernameRoutes = {
  loadRoutes(routes) {
    routes.push(
      {
        path: "/forgotusername",
        name: "forgot-username",
        component: ForgotUsername,
      },
      {
        path: "/forgotusername/processingusername",
        name: "processing-username",
        component: ProcessingUsername,
      },
      {
        path: "/forgotusername/:id",
        name: "username-info",
        component: UsernameInfo,
      },
      {
        path: "/invalidusername",
        name: "invalid-username",
        component: InvalidUsername,
      }
    )
    return routes
  },
}

export default forgotUsernameRoutes
