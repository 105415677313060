<template>
  <Loading v-if="loading" />
  <div v-else id="accountunlocked">
    <the-header></the-header>
    <div class="bg-container">
      <div class="bg-orange">
        <h1 class="bg-text">{{ msg }}</h1>
        <div class="svg-container">
          <svg
            aria-hidden="true"
            height="100%"
            width="100%"
            preserveAspectRatio="none"
            viewBox="0 0 100 10"
            class="coh-inline-element"
          >
            <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
          </svg>
        </div>
      </div>
    </div>

    <div class="form-container smaller-header" id="account-unlocked-successfully">
      <div class="noti-container">
        <div class="overlay-info">
          <div class="headImgContainer">
            <img
              srcset="@/assets/images/login.png 1x, @/assets/images/login-2x.png 2x"
              src="@/assets/images/login-2x.png"
              alt="computer with browser and login fields illustration"
            />
          </div>
          <div class="bg-text1" id="main">
            <h3 class="bg-text-line" tabindex="0">You may now login</h3>
          </div>
          <div class="loginBtn">
            <button
              type="button"
              id="account-success-login-btn"
              test-data="acct-success-login"
              :class="toLogin ? 'processing' : null"
              @click="goToLogin"
            >
              {{ loginBtn }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "@/components/header"
import TheFooter from "@/components/footer"
import accountRecovery from "@/app/recovery"
import Loading from "@/components/Loading"
import jQuery from "jquery"

export default {
  name: "AccountUnlocked",
  components: { TheHeader, TheFooter, Loading },
  data() {
    return {
      loginBtn: "LOG INTO YOUR ACCOUNT",
      systemErrorMsg: "",
      loading: false,
      msg: "Account Unlocked",
      msg2: "You may now login",
      toLogin: false,
      showErr: false,
    }
  },
  methods: {
    // Proxy call for account recovery.
    // Will run on page load to unlock account after email verification
    // takes id as payload
    async postAccountRecoveryData(id) {
      const resp = await accountRecovery.postAccountRecoveryData(id)

      if (resp && resp.data) {
        if (resp.data.message === "OK" && resp.status === 200) {
          console.log("status: " + resp.status)
          this.msg = "Account Unlocked"
          this.msg2 = "You may now login"
          this.showErr = false
        } else {
          // set boolean to determine which page loads for user
          this.showErr = true
        }
      }
    },

    goToLogin() {
      this.toLogin = true
      this.loginBtn = "HEADING TO LOGIN..."
      location.href = process.env.VUE_APP_FHB_ONLINE_URL
    },
  },
  async created() {
    // Unlock account on page load
    // on load, get id param and pass into proxy call
    this.loading = true
    await this.postAccountRecoveryData(this.$route.params.id)
    // if no errors, load page.
    // Send user to invalid page if error occurs or if no usernames returned
    if (this.showErr === false) {
      setTimeout(() => {
        this.loading = false
      }, 3000)
    } else {
      setTimeout(() => {
        this.$router.push({ name: "account-invalid" })
      }, 2000)
    }
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}

img {
  padding-top: 30px;
  padding-bottom: 15px;
  height: auto;
  width: 93.5px;
}

.bg-text-line {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
}

.bg-text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-text-line1 {
  margin: 0 0 0 0;
  font-weight: 450;
  font-size: 15.5px;
}

form {
  max-width: 480px;
  background: white;
  text-align: left;
  padding: 45px;
  padding-left: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  position: relative;
}

button {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid $white;
  color: $grayDark;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 40px;
  display: inline-block;
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;

  @include media-breakpoint-down(sm) {
    height: 50px;
    width: 185px;
    position: relative;
    background-color: #fec443;
    border: 1px solid $white;
    color: $grayDark;
    cursor: pointer;
    letter-spacing: 0.029em;
    border-radius: 30px;
    margin-top: 20px;
    display: inline-block;
    font-weight: 700;
    font-size: x-small;
    line-height: 24px;
    align-items: center;
    text-align: center;
    font-feature-settings: "cpsp" on;
  }
}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: $white;
}

.loginBtn {
  position: relative;
  text-align: center;
  padding-bottom: 50px;
}

.bottomLinksContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}

.processing {
  color: #e7e7e7;
}
</style>
