import { createStore } from "vuex"
import enrollHomeModule from "./modules/enroll_home"
import profileInfoModule from "./modules/profile_info"
import createUserIdModule from "./modules/create_userID"
import mfaModule from "./modules/mfa_data"
import accountRecoveryModule from "./modules/account_recovery"
import forgotUsernameModule from "./modules/forgot_username"
import fundnow_enroll from "./modules/fundnow_enroll"

export default new createStore({
  modules: {
    enrollhome: enrollHomeModule,
    profileInfo: profileInfoModule,
    createUserId: createUserIdModule,
    mfaData: mfaModule,
    accountRecovery: accountRecoveryModule,
    forgotUsername: forgotUsernameModule,
    fundnow: fundnow_enroll,
  },
})
