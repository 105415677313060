<template>
  <div id="enrollpage" @click="unlock">
    <the-header />
    <HomepageBg></HomepageBg>
    <div class="form-container" id="main">
      <div class="disclaimer">
        If you do not have a social security number, or if you are a business customer, please
        <a
          href="https://www.fhb.com/en/online-and-mobile-enrollment#manual-enroll"
          target="_blank"
          rel="noopener noreferrer"
          >enroll here</a
        >.
      </div>
      <template v-if="!this.internal_hide_form">
        <form>
          <div id="personal-page-errors" />
          <div v-if="showErr" class="page-error-container" data-test="enroll-page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
            </div>
            <div role="alert" class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div id="invalid-recaptcha" />
          <div
            v-if="invalidCaptcha"
            class="page-error-container"
            data-test="enroll-page-recaptcha-err"
          >
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
            </div>
            <div role="alert" class="page-error-msg">
              Your reCaptcha verification was unsuccessful. Please try again.
            </div>
          </div>

          <!-- Last Name input -->
          <div v-if="!showErr" class="lnameSpace"></div>
          <div class="ll-item ll-lname">
            <label for="lastName" id="applicant-lname"
              >Last Name <sup class="fhb-red">*</sup></label
            >
            <input
              :disabled="beforeSubmitBTN ? true : false"
              aria-required="true"
              aria-labelledby="applicant-lname"
              name="applicant-lname"
              maxlength="64"
              class="ll-textfield tf-lname"
              id="lastName"
              data-test="enroll-lname"
              type="text"
              v-on:blur="validateLName"
              v-model="lastName"
              :class="missingLName || invalidLName ? 'll-textfield-error' : 'll-textfield'"
            />
            <div
              v-if="missingLName || invalidLName"
              class="form-error-msg"
              data-test="enroll-lname-err"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">Please enter a valid last name</div>
            </div>
          </div>
          <div v-if="!missingLName && !invalidLName" class="emptySpace"></div>
          <div>
            <label for="dateOfBirth" id="dateOfBirth" class="dateOfBirthContainer"
              >Date of Birth <sup class="fhb-red">*</sup></label
            >
            <!-- <Birth Date Input /> -->
            <BirthdayInput
              :disabled="beforeSubmitBTN ? true : false"
              autocomplete="off"
              id="dob"
              v-model="dateOfBirth"
              @blur="validateDOB"
              aria-required="true"
              aria-labelledby="dateOfBirth"
              name="dateOfBirth"
              :class="missingAge || invalidAge ? 'll-textfield-error' : 'll-textfield'"
              data-test="enroll-dob"
            />
            <div v-if="invalidAge" class="form-error-msg" data-test="enroll-dob-invalid">
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">You must be 14 years or older to apply.</div>
            </div>
            <div v-else-if="missingAge" class="form-error-msg" data-test="enroll-dob-missing">
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">Please enter a valid date (MM/DD/YYYY)</div>
            </div>
            <div v-if="!missingAge && !invalidAge" class="emptySpace"></div>
          </div>
          <div>
            <!-- SSN input -->
            <div class="ssn-text-container">
              <label
                for="Social-Security-Number"
                id="Social-Security-Number"
                required.trim
                class="SSNlabel"
                >Social Security Number <sup class="fhb-red">*</sup>
                <!-- <span class="noSSN"><a href="/">I don't have a social security number</a></span> -->
              </label>
              <div class="noSSN">
                <a
                  name="business"
                  href="https://www.fhb.com/en/online-and-mobile-enrollment#manual-enroll"
                  >I don't have a social security number</a
                >
              </div>
            </div>
            <SsnInput
              :disabled="beforeSubmitBTN ? true : false"
              id="Social-Security-Number"
              name="Social-Security-Number"
              aria-required="true"
              aria-labelledby="Social-Security-Number"
              v-on:blur="validateSSN"
              v-model="ssn"
              :class="missingSSN || invalidSSN ? 'll-textfield-error' : 'll-textfield'"
              data-test="enroll-ssn"
            ></SsnInput>
            <p class="ssnPurposeText">
              Your Social Security Number (SSN) is used for identification purposes.
            </p>
            <div v-if="missingSSN || invalidSSN" class="form-error-msg" data-test="enroll-ssn-err">
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">
                Please enter a valid SSN.
                <span v-if="ITIN" role="alert" class="ITINerrorText">
                  ITIN numbers are not accepted.
                </span>
              </div>
            </div>
            <div v-if="!missingSSN && !invalidSSN" class="emptySpace"></div>
          </div>

          <!-- Type of Account selected -->
          <div v-if="!showErr" class="emptySpace"></div>
          <div>
            <p id="typeOfAcc" class="typeOfAcc label">
              Type of Account <sup class="fhb-red">*</sup>
            </p>
            <label
              class="selectAccountType"
              for="Type-of-Account-number"
              id="Type-of-Account-number"
            >
              <input
                :disabled="beforeSubmitBTN ? true : false"
                id="accountNumBtn"
                type="radio"
                v-model="typeOfAccount"
                name="typeOfAccountNum"
                value="typeAccountNumber"
                aria-required="true"
                aria-labelledby="Type-of-Account-number"
                data-test="enroll-acct-type"
              />
              <label class="accNumtext" for="accountNumBtn">Account Number</label>
              <span
                class="additonalBtn"
                @click="showExtendContext"
                @keyup.space="showExtendContext"
                tabindex="0"
              >
                <img
                  v-if="!extendContext"
                  class="form-error-icon"
                  srcset="@/assets/images/i.png 1x, @/assets/images/ix2.png 2x"
                  src="@/assets/images/ix2.png"
                  aria-hidden="true"
                />
                <img
                  v-if="extendContext"
                  class="form-error-icon"
                  srcset="@/assets/images/i-selected.png 1x, @/assets/images/i-selectedx2.png 2x"
                  src="@/assets/images/i-selectedx2.png"
                  aria-hidden="true"
                />
              </span>
            </label>
            <span v-if="extendContext" class="extendPop"
              >For line of credit accounts, please include the -1 at the end of your account
              numbers</span
            >
            <!-- <div v-if="!extendContext" class="spaceBar"></div> -->

            <label class="selectAccountType" for="Type-of-Credit-number" id="Type-of-Credit-number">
              <div class="ccInput">
                <input
                  :disabled="beforeSubmitBTN ? true : false"
                  id="CCBtn"
                  type="radio"
                  v-model="typeOfAccount"
                  name="typeOfCreditCardAccount"
                  value="typeCreditCardNumber"
                  aria-required="true"
                  aria-labelledby="Type-of-Credit-number"
                  data-test="enroll-cc-type"
                />
              </div>
              <div class="ccInput">
                <label class="accCreditCardtext" for="CCBtn">Credit Card Number</label>
              </div>
              <span
                class="additonalCCBtn"
                @click="showExtendCCinfo"
                @keyup.space="showExtendCCinfo"
                tabindex="0"
              >
                <img
                  v-if="!extendCCinfo"
                  class="form-error-icon"
                  srcset="@/assets/images/i.png 1x, @/assets/images/ix2.png 2x"
                  src="@/assets/images/ix2.png"
                  aria-hidden="true"
                />
                <img
                  v-if="extendCCinfo"
                  class="form-error-icon"
                  srcset="@/assets/images/i-selected.png 1x, @/assets/images/i-selectedx2.png 2x"
                  src="@/assets/images/i-selectedx2.png"
                  aria-hidden="true"
                />
              </span>
            </label>
            <span v-if="extendCCinfo" class="extendPop"
              >Only credit card numbers can be used. Debit card numbers are not accepted.</span
            >
            <!-- <div v-if="!extendCCinfo" class="emptySpace1"></div> -->
          </div>
          <div v-if="!showErr" class="emptySpace2"></div>
          <!-- FHB Account number shows if got selected -->
          <div v-if="typeOfAccount === 'typeAccountNumber'">
            <label for="FHB-Account-Number" id="FHB-Account-Number"
              >FHB Account Number <sup class="fhb-red">*</sup></label
            >
            <input
              :disabled="beforeSubmitBTN ? true : false"
              id="FHBaccNum"
              type="text"
              v-model.trim="FHBaccountNumber"
              v-on:blur="checkFHBaccountNumber"
              aria-required="true"
              aria-labelledby="FHB-Account-Number"
              name="FHB-Account-Number"
              :class="missingFHBaccountNumber ? 'll-textfield-error' : 'll-textfield'"
              data-test="enroll-acct-num"
            />
            <div
              v-if="missingFHBaccountNumber"
              class="form-error-msg"
              data-test="enroll-acct-num-missing"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">Please enter a valid FHB Account Number</div>
            </div>
            <div v-if="!missingFHBaccountNumber" class="emptySpace"></div>
            <label
              for="comfirm-Account-Number"
              id="comfirm-Account-Number"
              class="comfirmAccountNumberLable"
              >Confirm FHB Account Number <sup class="fhb-red">*</sup></label
            >
            <input
              :disabled="beforeSubmitBTN ? true : false"
              id="ConfirmFHBaccNum"
              type="text"
              v-model.trim="confrimFHBaccountNumber"
              v-on:blur="checkConfirmAccountNumber"
              aria-required="true"
              aria-labelledby="comfirm-Account-Number"
              :class="invalidFHBaccountNumber ? 'll-textfield-error' : 'll-textfield'"
              data-test="enroll-confirm-acct-num"
            />
            <div
              v-if="invalidFHBaccountNumber || missingConfirmFHBaccountNumber"
              class="form-error-msg"
              data-test="enroll-confirm-acct-num-err"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">FHB Account Number doesn't match</div>
            </div>
            <div v-if="!invalidFHBaccountNumber" class="emptySpace"></div>
            <!-- <div v-if="!invalidFHBaccountNumber" class="emptySpace"></div>
            <div v-if="!invalidFHBaccountNumber" class="emptySpace"></div> -->
          </div>

          <!-- Credit card number shows if got selected -->
          <div v-if="typeOfAccount === 'typeCreditCardNumber'">
            <label for="Credit-Card-Number" id="Credit-Card-Number"
              >Credit Card Number <sup class="fhb-red">*</sup></label
            >
            <input
              :disabled="beforeSubmitBTN ? true : false"
              id="creditCardNum"
              type="text"
              maxlength="16"
              v-model.number="creditCardNum"
              v-on:blur="checkValidateCreditCard"
              aria-required="true"
              aria-labelledby="Credit-Card-Number"
              :class="
                missingCreditCardNumber || invalidCreditCardNumber
                  ? 'll-textfield-error'
                  : 'll-textfield'
              "
              data-test="enroll-confirm-cc-num"
            />
            <div
              v-if="invalidCreditCardNumber || missingCreditCardNumber"
              class="form-error-msg"
              data-test="enroll-confirm-cc-num-err"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">Please enter valid credit card number</div>
            </div>
            <div
              v-if="!invalidCreditCardNumber && !missingCreditCardNumber"
              class="emptySpace"
            ></div>

            <label for="expiration-Date" id="expiration-Date" class="expirationDateLable"
              >Expiration Date <sup class="fhb-red">*</sup></label
            >
            <input
              :disabled="beforeSubmitBTN ? true : false"
              id="expDate"
              v-model.trim="expirationDate"
              v-on:blur="checkInvalidExpirationDate"
              maxlength="7"
              placeholder="MM/YYYY"
              aria-required="true"
              aria-labelledby="expiration-Date"
              :class="
                missingExprationDate || invalidExprationDate ? 'll-textfield-error' : 'll-textfield'
              "
              data-test="enroll-cc-exp"
            />
            <div
              v-if="missingExprationDate || invalidExprationDate"
              class="form-error-msg"
              data-test="enroll-cc-exp-err"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">Please enter valid Expiration Date</div>
            </div>
            <div v-if="!missingExprationDate && !invalidExprationDate" class="emptySpace"></div>

            <label for="cvv" id="cvv" class="cvvLabel">CVV <sup class="fhb-red">*</sup></label>
            <input
              :disabled="beforeSubmitBTN ? true : false"
              id="cvv"
              aria-describedby="cvv-input"
              v-model="cvv"
              v-on:blur="checkValidateCVV"
              maxlength="3"
              placeholder="123"
              aria-required="true"
              aria-labelledby="cvv"
              :class="invalidCVV ? 'll-textfield-error' : 'll-textfield'"
              data-test="enroll-cvv"
            />
            <div>3 digit security code (CVV) found on the back of your card</div>
            <div v-if="invalidCVV" id="cvv-input" class="form-error-msg" data-test="enroll-cvv-err">
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">Please enter valid CVV</div>
            </div>
            <div v-if="wrongCVV" class="form-error-msg" data-test="enroll-incorrect-cvv-err">
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                aria-hidden="true"
              />
              <div role="alert" class="form-error-txt">
                The 3 digit security code (CVV) you entered is incorrect.
              </div>
            </div>
            <div v-if="!invalidCVV" class="emptySpace"></div>
          </div>
          <div v-if="!showErr" class="emptySpace1"></div>
          <div class="submitBtn">
            <button
              v-if="!isCypressTest"
              type="button"
              id="enrollment-submit-button"
              :class="beforeSubmitBTN ? 'processing' : null"
              @click="startRecaptcha"
              data-test="enroll-submit-btn"
            >
              <img
                v-if="continueBtn === 'PROCESSING...'"
                class="spinner"
                srcset="
                  @/assets/images/yellow-spinner.png    1x,
                  @/assets/images/yellow-spinner-2x.png 2x
                "
                src="@/assets/images/yellow-spinner.png"
                aria-hidden="true"
              />
              {{ continueBtn }}
            </button>
            <button
              class="submitBtn"
              v-else
              type="button"
              id="enrollment-submit-button"
              :class="beforeSubmitBTN ? 'processing' : null"
              @click="validationPage"
              data-test="enroll-submit-btn"
            >
              <img
                v-if="continueBtn === 'PROCESSING...'"
                class="spinner"
                srcset="
                  @/assets/images/yellow-spinner.png    1x,
                  @/assets/images/yellow-spinner-2x.png 2x
                "
                src="@/assets/images/yellow-spinner.png"
                aria-hidden="true"
              />
              {{ continueBtn }}
            </button>
          </div>
          <!--Recaptcha challenge displays if user is determined to be suspicous-->
          <div v-if="!isCypressTest" id="reCaptcha" data-test="enroll-recaptcha">
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="siteKey"
              @verify="checkRecaptcha"
              size="invisible"
            >
            </vue-recaptcha>
          </div>
        </form>
      </template>
      <div v-else class="content-container hide-form">
        <div class="maintenance-message overlay-content">
          <div class="msg">
            We apologize for the inconvenience, FHB Online & Mobile Banking enrollment is currently
            unavailable. We are working to resolve the issue as soon as possible. Please try again
            later.
          </div>
        </div>
      </div>
      <!-- Maintenance -->
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Validator from "@/app/validation"
import jQuery from "jquery"
import TheHeader from "@/components/header"
import TheFooter from "@/components/footer"
import HomepageBg from "@/components/homepagebg"
import SsnInput from "../components/CustomInputs/ssnInput.vue"
import BirthdayInput from "../components/CustomInputs/BirthdayInput.vue"
import RetailApi from "@/app/retail-api"
import RecaptchaAPI from "@/app/recaptcha"
import { VueRecaptcha } from "vue-recaptcha"
import environments from "@/app/environments"

export default {
  name: "HomeLanding",
  components: { TheHeader, TheFooter, HomepageBg, SsnInput, VueRecaptcha, BirthdayInput },
  data() {
    return {
      internal_hide_form: false,
      continueBtn: "CONTINUE",
      errContainer: [],
      missingLName: false,
      invalidLName: false,
      processing: false,
      invalidAge: false,
      missingAge: false,
      missingSSN: false,
      invalidSSN: false,
      missingFHBaccountNumber: false,
      invalidFHBaccountNumber: false,
      missingConfirmFHBaccountNumber: false,
      missingCreditCardNumber: false,
      invalidCreditCardNumber: false,
      missingExprationDate: false,
      invalidExprationDate: false,
      showErr: false,
      beforeSubmitBTN: false,
      invalidCaptcha: false,
      siteKey: "6LfOFN0iAAAAAJwRiZo3_FsKG7sppy4mHj6uaUNp",
      errLastName: false,
      errBOD: false,
      errSSN: false,
      errAccNum: false,
      errConfirmAccNum: false,
      errCCNum: false,
      errCVV: false,
      errExpirationDate: false,
      formatDOB: "",
      extendContext: false,
      extendCCinfo: false,
      ITIN: false,
      post_api_err: "",
      cvvLoader: false,
      isVerified: true,
      invalidCVV: false,
      wrongCVV: false,
    }
  },
  created() {
    this.internal_hide_form = environments.InternalHideForm()
    this.setCypress(!environments.isProd() && this.$route.query.cypress == "true")
    // check to see if OAO query param is present
    if (this.$route.query.from_oao) {
      this.from_oao = this.$route.query.from_oao
    }
  },
  computed: {
    ...mapGetters("enrollhome", [
      "applicantLastName",
      "applicantDob",
      "applicantSsn",
      "applicantTypeOfAcc",
      "applicantAccNum",
      "applicantConfirmAccNum",
      "applicantCreditCardNum",
      "applicantCardExpirationDate",
      "applicantPhone",
      "applicantEmail",
      "applicantID",
      "applicantAge",
      "isCypressTest",
      "getOAO",
      "getApplicantMobileFromTSYS",
      "getApplicantEmailFromTSYS",
      "getApplicantCardCVV",
    ]),
    ...mapGetters("mfaData", ["loading"]),
    allowToPass: {
      get() {
        return this.checkAllow()
      },
    },
    lastName: {
      get() {
        return this.applicantLastName
      },
      set(value) {
        this.setApplicantLName(value)
      },
    },
    dateOfBirth: {
      get() {
        return this.applicantDob
      },
      set(value) {
        this.setApplicantDOB(value)
      },
    },
    ssn: {
      get() {
        return this.applicantSsn
      },
      set(value) {
        this.setApplicantSSN(value)
      },
    },
    typeOfAccount: {
      get() {
        return this.applicantTypeOfAcc
      },
      set(value) {
        this.setTypeOfAcc(value)
      },
    },
    FHBaccountNumber: {
      get() {
        return this.applicantAccNum
      },
      set(value) {
        this.setAccNum(value)
      },
    },
    confrimFHBaccountNumber: {
      get() {
        return this.applicantConfirmAccNum
      },
      set(value) {
        this.setConfirmAccNum(value)
      },
    },
    creditCardNum: {
      get() {
        return this.applicantCreditCardNum
      },
      set(value) {
        this.setCreditCardNum(value)
      },
    },
    expirationDate: {
      get() {
        return this.applicantCardExpirationDate
      },
      set(value) {
        this.setCardExpirationDate(value)
      },
    },
    cvv: {
      get() {
        return this.getApplicantCardCVV
      },
      set(value) {
        this.setCardCVV(value)
      },
    },
    phone: {
      get() {
        return this.applicantPhone
      },
      set(value) {
        this.setPhone(value)
      },
    },
    email: {
      get() {
        return this.applicantEmail
      },
      set(value) {
        this.setEmail(value)
      },
    },
    mobileFromTSYS: {
      get() {
        return this.getApplicantMobileFromTSYS
      },
      set(value) {
        this.setMobileFromTSYS(value)
      },
    },
    emailFromTSYS: {
      get() {
        return this.getApplicantEmailFromTSYS
      },
      set(value) {
        this.setEmailFromTSYS(value)
      },
    },
    id: {
      get() {
        return this.id
      },
      set(value) {
        this.setID(value)
      },
    },
    enrollAge: {
      get() {
        return this.applicantAge
      },
      set(value) {
        this.setAge(value)
      },
    },
    enrollAddress: {
      get() {
        return this.applicantAddressExists
      },
      set(value) {
        this.setAddressExists(value)
      },
    },
    from_oao: {
      get() {
        return this.getOAO
      },
      set(value) {
        this.setOAO(value)
      },
    },
  },
  methods: {
    ...mapActions("enrollhome", [
      "setApplicantLName",
      "setApplicantDOB",
      "setApplicantSSN",
      "setTypeOfAcc",
      "setAccNum",
      "setConfirmAccNum",
      "setCreditCardNum",
      "setCardExpirationDate",
      "setPhone",
      "setEmail",
      "setID",
      "setAge",
      "setAddressExists",
      "setCypress",
      "setOAO",
      "setMobileFromTSYS",
      "setEmailFromTSYS",
      "setCardCVV",
    ]),
    toManual() {
      window.location.href = "https://www.fhb.com/en/online-and-mobile-enrollment#manual-enroll"
    },
    unlock() {
      this.extendContext = false
      this.extendCCinfo = false
    },
    showExtendContext(event) {
      event.stopPropagation()
      this.extendContext = !this.extendContext
    },
    showExtendCCinfo(event) {
      event.stopPropagation()
      this.extendCCinfo = !this.extendCCinfo
    },
    ...mapActions("mfaData", ["updateToken", "updateAccInfo"]),

    onFocusDate() {
      document.getElementById("dob").type = "date"
    },
    // Function used in @verify attribute of reCaptcha to get response token
    async checkRecaptcha(response) {
      // Continue button should change to processing after reCaptcha is completed
      this.handleCTN()

      const payload = {
        response: response,
      }

      const resp = await RecaptchaAPI.verifyResponseToken(payload)

      if (resp && resp.data) {
        if (resp.data.message === "OK" && resp.status === 200) {
          // good response and token
          this.invalidCaptcha = false
        } else if (resp.data.message !== "OK" || resp.status !== 200) {
          // response received from API call was not a 200
          this.invalidCaptcha = true
        }

        this.checkInvalidCaptcha(this.invalidCaptcha)
      }
    },

    checkFHBaccountNumber() {
      this.missingFHBaccountNumber = !this.FHBaccountNumber
      if (this.missingFHBaccountNumber) {
        this.errAccNum = true
      }
      if (!this.missingFHBaccountNumber) {
        this.errAccNum = false
      }
      this.checkErrCount()
    },

    // confirm with account number
    checkConfirmAccountNumber() {
      if (
        this.FHBaccountNumber !== this.confrimFHBaccountNumber ||
        this.confrimFHBaccountNumber.length < 1
      ) {
        this.invalidFHBaccountNumber = true
        this.missingConfirmFHBaccountNumber = true
      } else {
        this.invalidFHBaccountNumber = false
        this.missingConfirmFHBaccountNumber = false
      }
      if (this.missingConfirmFHBaccountNumber || this.invalidFHBaccountNumber) {
        this.errConfirmAccNum = true
      }
      if (!this.missingConfirmFHBaccountNumber && !this.invalidFHBaccountNumber) {
        this.errConfirmAccNum = false
      }
      this.checkErrCount()
    },

    //check global err message for every single input field
    checkErrCount() {
      if (this.typeOfAccount === "typeAccountNumber") {
        if (
          !this.errLastName &&
          !this.errBOD &&
          !this.errSSN &&
          !this.errAccNum &&
          !this.errConfirmAccNum
        ) {
          this.showErr = false
        } else {
          this.showErr = true
        }
      }
      if (this.typeOfAccount === "typeCreditCardNumber") {
        if (
          !this.errLastName &&
          !this.errBOD &&
          !this.errSSN &&
          !this.errCCNum &&
          !this.errExpirationDate &&
          !this.errCVV
        ) {
          this.showErr = false
        } else {
          this.showErr = true
        }
      }
    },

    // validate last name
    validateLName() {
      this.lastName = this.lastName.trim().replace(/\s\s+/g, " ")
      this.missingLName = !this.lastName
      if (!this.missingLName) {
        this.invalidLName = !Validator.isValidName(this.lastName)
      }
      if (this.missingLName || this.invalidLName) {
        this.errLastName = true
      }
      if (!this.missingLName && !this.invalidLName) {
        this.errLastName = false
      }
      this.checkErrCount()
    },

    // validate date of birth
    validateDOB() {
      this.missingAge = !this.dateOfBirth
      const pickedDate = this.dateOfBirth
      const dateStr = pickedDate.replaceAll("/", "").split("-").join("")
      const y1 = dateStr.slice(4, 8)
      const m1 = dateStr.slice(0, 2)
      const d1 = dateStr.slice(2, 4)
      this.formatDOB = y1 + "-" + m1 + "-" + d1
      const date = new Date()
      let d2 = date.getDate()
      let m2 = 1 + date.getMonth()
      let y2 = date.getFullYear()
      const month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      if (d1 > d2) {
        d2 = d2 + month[m2 - 1]
        m2 = m2 - 1
      }
      if (m1 > m2) {
        m2 = m2 + 12
        y2 = y2 - 1
      }
      const y = y2 - y1
      this.setAge(y)
      if (y < 14) {
        this.invalidAge = true
      } else {
        this.invalidAge = false
      }
      if (this.missingAge || this.invalidAge) {
        this.errBOD = true
      }
      if (!this.missingAge && !this.invalidAge) {
        this.errBOD = false
      }
      if (y1.length < 4 || m1 > 12 || d1 > 31) {
        this.missingAge = true
      }
      this.checkErrCount()
    },

    // validate SSN
    validateSSN() {
      this.missingSSN = !this.ssn
      if (this.ssn.toString()[0] == "9") {
        this.ITIN = true
      } else {
        this.ITIN = false
      }

      if (this.ssn.toString().length < 11 || this.ssn.toString()[0] == "9") {
        this.invalidSSN = true
      }
      if (this.ssn.toString().length === 11 && this.ssn.toString()[0] !== "9") {
        this.invalidSSN = false
      }
      if (this.missingSSN || this.invalidSSN) {
        this.errSSN = true
      }
      if (!this.missingSSN && !this.invalidSSN) {
        this.errSSN = false
      }
      this.checkErrCount()
    },

    // validate type of Account
    validateTypeOfAcc() {
      if (this.typeOfAccount === "typeAccountNumber") {
        this.creditCardNum = ""
        this.expirationDate = ""
        this.cvv = ""
      } else {
        this.FHBaccountNumber = ""
        this.confrimFHBaccountNumber = ""
      }
    },

    // validate misssing credit card number
    validateMissingCreditCard() {
      this.missingCreditCardNumber = !this.creditCardNum
    },

    // validate invalid credit card number
    checkValidateCreditCard() {
      if (this.creditCardNum.toString().length !== 16) {
        this.invalidCreditCardNumber = true
      } else {
        this.invalidCreditCardNumber = false
        this.missingCreditCardNumber = false
      }
      if (this.invalidCreditCardNumber || this.missingCreditCardNumber) {
        this.errCCNum = true
      }
      if (!this.invalidCreditCardNumber && !this.missingCreditCardNumber) {
        this.errCCNum = false
      }
      this.checkErrCount()
    },

    // validate invalid cvv value
    checkValidateCVV() {
      if (this.cvv.length !== 3 || !Validator.isValidNumber(this.cvv)) {
        this.invalidCVV = true
        this.errCVV = true
      } else {
        this.invalidCVV = false
        this.errCVV = false
      }
      this.checkErrCount()
    },

    // validate Expiration Date
    checkMissingExpirationDate() {
      this.missingExprationDate = !this.expirationDate
    },

    checkInvalidExpirationDate() {
      let pickedDate = ""
      const slash = /\//g
      const hyphen = /-/g
      let str = this.expirationDate.replaceAll("-", "").replaceAll("/", "")
      if (this.expirationDate.match(slash)) {
        pickedDate = this.expirationDate.replace("/", "")
      } else if (this.expirationDate.match(hyphen)) {
        pickedDate = this.expirationDate.replace("-", "")
        this.expirationDate = this.expirationDate.replace("-", "/")
      }
      if (this.expirationDate.length === 6) {
        this.expirationDate =
          this.expirationDate.slice(0, 2) + "/" + this.expirationDate.slice(2, 6)
        pickedDate = this.expirationDate.replace("/", "")
      }

      const y1 = pickedDate.slice(2, 6)
      const m1 = pickedDate.slice(0, 2)
      if (m1 > "12" || m1 == "00" || str.length !== 6) {
        this.invalidExprationDate = true
      } else {
        let today, someday
        today = new Date()
        someday = new Date()
        someday.setFullYear(y1, m1, 1)
        if (someday < today) {
          this.invalidExprationDate = true
        } else {
          this.invalidExprationDate = false
          this.missingExprationDate = false
        }
      }
      if (this.missingExprationDate || this.invalidExprationDate) {
        this.errExpirationDate = true
      }
      if (!this.missingExprationDate && !this.invalidExprationDate) {
        this.errExpirationDate = false
      }
      this.checkErrCount()
    },

    // check allow to pass before submit
    checkAllow() {
      if (this.typeOfAccount === "typeAccountNumber") {
        if (
          this.missingLName ||
          this.invalidLName ||
          this.invalidAge ||
          this.missingAge ||
          this.missingSSN ||
          this.invalidSSN ||
          this.missingFHBaccountNumber ||
          this.invalidFHBaccountNumber ||
          this.invalidCaptcha ||
          this.invalidResponse
        ) {
          return false
        } else {
          return true
        }
      } else if (this.typeOfAccount === "typeCreditCardNumber") {
        if (
          this.missingLName ||
          this.invalidLName ||
          this.invalidAge ||
          this.missingAge ||
          this.missingSSN ||
          this.invalidSSN ||
          this.missingCreditCardNumber ||
          this.invalidCreditCardNumber ||
          this.invalidCVV ||
          this.missingExprationDate ||
          this.invalidExprationDate ||
          this.invalidCaptcha ||
          this.invalidResponse
        ) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    //calling BE to fetch and validate user info
    async getUserInfo(payload) {
      const resp = await RetailApi.getUserInfo(payload)
      console.log("---Userdata response ---")
      console.log(resp)
      // console.log(resp.data.response.phone)
      // console.log(resp.data.response.email)
      if (this.allowToPass && resp.status >= 400) {
        if (resp.data?.response?.is_business == true) {
          this.$router.push("/businessaccount")
        } else if (resp.data?.response?.invalid_cvv) {
          this.wrongCVV = true
          this.continueBtn = "CONTINUE"
          this.beforeSubmitBTN = false
          this.$refs.recaptcha.reset()
        } else {
          this.$router.push("/visitbranch")
        }
      } else {
        if (this.allowToPass && resp.data.response.valid === false) {
          if (resp.data?.response?.is_business == true) {
            this.$router.push("/businessaccount")
          } else {
            this.$router.push("/visitbranch")
          }
        }
        if (this.allowToPass && resp.data.response.valid) {
          this.phone = resp.data.response.phone
          this.email = resp.data.response.email
          this.mobileFromTSYS = resp.data.response.tsys_mobile
          this.emailFromTSYS = resp.data.response.tsys_email
          this.id = resp.data.response.id
          if (resp.data.response.enrolled || !resp.data.response.address_exists) {
            this.$router.push("/visitbranch")
          }
          if (!resp.data.response.enrolled) {
            this.setAddressExists(resp.data.response.address_exists)

            if (this.isCypressTest) {
              this.updateToken(resp.data.response.token)
              this.updateAccInfo([resp.data.response.accounts])
            }
            if (this.typeOfAccount === "typeAccountNumber") {
              // if customer has no contact information
              if (resp.data.response.phone.length == 0 && !resp.data.response.email) {
                this.$router.push("/visitbranch")
              } else {
                this.$router.push("/enhancedsecurity")
              }
            } else if (this.typeOfAccount === "typeCreditCardNumber") {
              // if customer has no contact information on core
              if (resp.data.response.phone.length == 0 && !resp.data.response.email) {
                // if customer has no contact info on both core and TSYS
                if (this.mobileFromTSYS.length == 0 && !this.emailFromTSYS) {
                  this.$router.push("/visitbranch")
                }
                // if customer has both contact info from TSYS
                else if (this.mobileFromTSYS.length > 0 || this.emailFromTSYS) {
                  this.$router.push("/enhancedsecurity")
                }
              } else {
                // if customer has core phone or email
                this.$router.push("/enhancedsecurity")
              }
            } else {
              this.$router.push("/visitbranch")
            }
          }
        }
      }
    },

    // check if to show global Error message
    errCnt() {
      if (!this.allowToPass) {
        this.showErr = true
      } else {
        this.showErr = false
      }
    },
    // change submit button status
    handleCTN() {
      if (this.allowToPass) {
        this.beforeSubmitBTN = true
        this.continueBtn = "PROCESSING..."
      } else {
        this.continueBtn = "CONTINUE"
      }
    },

    // push err input to error container
    pushErrors() {
      if (this.missingLName || this.invalidLName) {
        this.errContainer.push("lastName")
      }
      if (this.missingAge || this.invalidAge) {
        this.errContainer.push("dob")
      }
      if (this.missingSSN || this.invalidSSN) {
        this.errContainer.push("ssn")
      } else if (this.typeOfAccount === "typeAccountNumber") {
        if (this.missingFHBaccountNumber) {
          this.errContainer.push("FHBaccNum")
        }
        if (this.invalidFHBaccountNumber) {
          this.errContainer.push("ConfirmFHBaccNum")
        }
      } else if (this.typeOfAccount === "typeCreditCardNumber") {
        if (this.missingCreditCardNumber || this.invalidCreditCardNumber) {
          this.errContainer.push("creditCardNum")
        }
        if (this.missingExprationDate || this.invalidExprationDate) {
          this.errContainer.push("expDate")
        }
        if (this.invalidCVV) {
          this.errContainer.push("cvv")
        }
      }
    },

    // validate error to scroll Top or focus on single input
    checkErr() {
      if (this.errContainer.length > 1 ? jQuery("html, body").animate({ scrollTop: 0 }) : null) {
        this.errContainer = []
      }
      if (this.errContainer.length === 1) {
        document.getElementById(this.errContainer[0]).focus()
        this.errContainer = []
      } else if (this.errContainer.length === 0) {
        this.errContainer = []
      }
    },

    // function used during form submission to start reCaptcha
    startRecaptcha() {
      this.checkBeforeRecaptcha()
      // if no form errors, run reCaptcha
      const pass = this.errContainer.length == 0
      if (pass) {
        this.$refs.recaptcha.execute()
      } else {
        // display errors if present
        this.checkErr()
      }
    },

    // Function to check form before running reCaptcha
    // resets reCaptcha variables to false before running reCaptcha again
    checkBeforeRecaptcha() {
      this.invalidCaptcha = false
      this.validateLName()
      this.validateDOB()
      this.validateSSN()
      this.checkFHBaccountNumber()
      this.checkConfirmAccountNumber()
      this.validateMissingCreditCard()
      this.checkValidateCreditCard()
      this.checkValidateCVV()
      this.checkMissingExpirationDate()
      this.checkInvalidExpirationDate()
      this.errCnt()
      this.pushErrors()
    },

    checkInvalidCaptcha(invalid) {
      // If form and response from server was good, then continue validations
      if (invalid === false) {
        this.validationPage()
      }
      // if reCaptcha response from server states the reCaptcha challenge is invalid or if response from proxy is invalid
      else {
        this.resetInvalidRecaptcha()
        jQuery("html, body").animate({ scrollTop: 0 })
      }
    },

    // function to reset recaptcha if an invalid server response is received
    resetInvalidRecaptcha() {
      this.beforeSubmitBTN = false
      this.continueBtn = "CONTINUE"
      this.setApplicantLName("")
      this.setApplicantDOB("")
      this.setApplicantSSN("")
      this.setAccNum("")
      this.setAccNum("")
      this.setConfirmAccNum("")
      this.setCreditCardNum("")
      this.setCardExpirationDate("")
      this.missingLName = false
      this.invalidAge = false
      this.missingAge = false
      this.missingSSN = false
      this.invalidSSN = false
      this.missingCreditCardNumber = false
      this.invalidCreditCardNumber = false
      this.invalidCVV = false
      this.missingExprationDate = false
      this.invalidExprationDate = false
      this.$refs.recaptcha.reset()
    },

    // final validation before submit
    validationPage() {
      this.validateLName()
      this.validateDOB()
      this.validateSSN()
      this.checkFHBaccountNumber()
      this.checkConfirmAccountNumber()
      this.validateMissingCreditCard()
      this.checkValidateCreditCard()
      this.checkValidateCVV()
      this.checkMissingExpirationDate()
      this.checkInvalidExpirationDate()
      this.validateTypeOfAcc()
      /* eslint-disable */
      const payload = {
        last_name: this.applicantLastName,
        date_of_birth: this.formatDOB,
        ssn: this.applicantSsn.replaceAll("-", ""),
        bypass_mfa: this.isCypressTest,
      }
      // if account is selected, only allow acct_num in payload
      if (this.applicantTypeOfAcc === "typeAccountNumber") {
        payload.acct_num = this.applicantAccNum.padStart(10, "0")
      }

      if (this.applicantTypeOfAcc === "typeCreditCardNumber") {
        payload.cc_num = this.applicantCreditCardNum.toString()
        payload.cc_exp = this.applicantCardExpirationDate.replace("/", "-")
        payload.card_verification_value = this.cvv
        payload.verify_previous_value = "NO"
      }
      this.getUserInfo(payload)
      this.errCnt()
      this.pushErrors()
      this.checkErr()
      this.handleCTN()
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.lnameSpace {
  height: 10px;
}

#typeOfAcc {
  padding-top: 0px;
  // margin-top: 0px;
}

.ITINerrorText {
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: $redDark;
}

.accNumtext:hover {
  cursor: pointer;
}

.accCreditCardtext:hover {
  cursor: pointer;
}

#Type-of-Credit-number {
  padding-top: 10px;
}

.extendPop {
  // border: 1px solid black;
  display: block;
  box-shadow: 0px 0px 5px 3px rgba(175, 175, 175, 0.25);
  border-radius: 6px;
  padding: 8px 15px;
  width: calc(80% - 2rem);
  margin-left: 30px;
  position: absolute;
  z-index: 90;
  background-color: white;
}

#Credit-Card-Number {
  margin-top: 15px;
}

#FHB-Account-Number {
  margin-top: 15px;
}

#CCBtn {
  // position: relative;
  margin-top: -25px;
}

#Type-of-Account-number {
  margin-bottom: 0;
}

.spaceBar {
  height: 56px;
}

.emptySpace1 {
  height: 30px;
}

.emptySpace2 {
  height: 18px;
}

.additonalBtn {
  // border: 1px solid black;
  // width: auto;
  // height: auto;
  // border-radius: 0;
  // background-color: white;
  margin-left: 10px;
  margin-top: 25px;
}

.additonalCCBtn {
  margin-left: 10px;
}

.additonalBtn:hover {
  cursor: pointer;
}

.additonalCCBtn:hover {
  cursor: pointer;
}

input {
  display: block;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  color: #333333;
  border: 1px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  padding: 19px;
  border-radius: 2px;
}

input[type="radio"] {
  display: inline-block;
  width: 20px;
  margin: 0 10px 0 0;
  position: relative;
  top: 16px;
  box-shadow: none;
  margin-right: 15px;
  background-color: #fec443;
}

button {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 60px;
  display: inline-block;
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

#dob {
  padding: 10px 15.5px 11px 15px;
}

.selectAccountType input {
  cursor: pointer;
}

.selectAccountType input {
  cursor: pointer;
}

.ssnPurposeText {
  position: relative;
  margin: 5px 0 0;
}

/* a:hover,
a:active, */
button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.submitBtn {
  display: relative;
  text-align: center;

  button {
    font-size: 0.8rem;
  }

  img {
    text-align: center;
    vertical-align: middle;
  }
}

::placeholder {
  color: #bbbbbb;
  opacity: 1;
  /* Firefox */
}

.noSSN {
  font-weight: 450;
  font-size: 16px;
  // position: relative;
  // margin-left: 50px;
  // padding-left: 15%;
  margin-top: 23px;
  // height: 18px;
  color: #222222;
  // float: right;
}

.ssn-text-container {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }

  margin-top: 0px;
}

.typeOfAcc {
  margin-bottom: 0px;
  color: $black;
  font-weight: bold;
}

.selectAccountType {
  display: flex;
  margin-top: 0;
}

.accNumtext {
  // padding-right: 48px;
  margin-top: 25px;
}

.accCreditCardtext {
  margin-top: 0;
}

.fhb-red {
  color: #a1252c;
}

.processing {
  color: #e7e7e7;
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;
}

.emptySpace {
  // position: relative;
  height: 21.6px;
}

/*.dateOfBirthContainer {
  margin-top: 15px;
}

.comfirmAccountNumberLable {
  margin-top: 10px;
}

.expirationDateLable {
  margin-top: 10px;
}*/

.grecaptcha-badge {
  visibility: hidden;
}

#reCaptcha {
  z-index: 100;
  position: absolute;
}

.disclaimer {
  font-size: 1em;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 620px;
  position: relative;
  padding: 0 32px;
}
.maintenance-message {
  color: $red;
}
</style>
