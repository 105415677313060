<template>
  <input
    type="text"
    placeholder="MM/DD/YYYY"
    maxlength="10"
    :value="modelValue"
    @keydown="validateKeydown"
    @focus="removeMask"
    @blur="formatInput"
    autocomplete="off"
  />
</template>

<script>
export default {
  name: "BirthdayInput",
  emits: ["blur", "update:modelValue"],
  props: {
    modelValue: String,
  },
  data() {
    return {
      raw: "",
      formatted: "",
    }
  },
  computed: {
    cmdKey: {
      get() {
        if (this.getBrowser() == "firefox") {
          return 224
        }
        // chrome safari
        return 91
      },
    },
  },
  methods: {
    getBrowser() {
      let agent = navigator.userAgent
      if (agent.match("/chrome|chromium|crios/i")) return "chrome"
      if (agent.match("/firefox|fxios/i")) return "firefox"
      if (agent.match("/safari/i")) return "safari"
      if (agent.match("/edg/i")) return "edge"
    },
    validateKeydown(e) {
      var key = e.key // event.key press
      // if non-number prevent typing
      if (
        !(
          key == "Backspace" ||
          key == "ArrowLeft" ||
          key == "ArrowRight" ||
          key == "Tab" ||
          key == "Delete" ||
          e.keycode == this.cmdKey ||
          e.ctrlKey
        )
      ) {
        if (key.match(`[^0-9/cv]+`)) {
          e.preventDefault()
        }
      }
    },
    formatInput(e) {
      this.formatted = this.wearMask(e.target.value.replace(/\D/g, ""))
      this.$emit("update:modelValue", this.formatted)
      this.$emit("blur")
    },
    wearMask(str) {
      if (str === null || str.length == 0) {
        return ``
      }
      if (str.length > 8) {
        str = str.substring(0, 8)
      }

      let formatted = this.addDOBSlashes(str)
      return formatted
    },
    removeMask(e) {
      this.raw = e.target.value.replaceAll("/", "")
      this.$emit("update:modelValue", this.raw)
    },
    addDOBSlashes(str) {
      if (str.length <= 2) {
        return str
      }
      let a = str.substring(0, 2)
      let b = str.substring(2, 4)
      if (str.length <= 4) {
        return `${a}/${b}`
      }
      let c = str.substring(4, 8)
      return `${a}/${b}/${c}`
    },
  },
}
</script>
