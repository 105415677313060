export default {
  namespaced: true,
  state: {
    sso_id: "",
    token: "",
    enrollment_id: "",

    username: "",
    login_username: "",
  },
  actions: {
    setSSOID({ commit }, val) {
      commit("setSSOID", val)
    },
    setEnrollmentID({ commit }, val) {
      commit("setEnrollmentID", val)
    },
    setToken({ commit }, val) {
      commit("setToken", val)
    },
    setUsername({ commit }, email) {
      commit("setUsername", email)
    },
    setLoginUser({ commit }, email) {
      commit("setLoginUser", email)
    },
  },
  mutations: {
    setSSOID(state, value) {
      state.sso_id = value
    },
    setEnrollmentID(state, value) {
      state.enrollment_id = value
    },
    setToken(state, value) {
      state.token = value
    },
    setUsername(state, value) {
      state.username = value
    },
    setLoginUser(state, value) {
      state.login_username = value
    },
  },
  getters: {
    getSSOID: state => state.sso_id,
    getEnrollmentID: state => state.enrollment_id,
    getToken: state => state.token,
    getUsername: state => state.username,
    getLoginUser: state => state.login_username,
  },
}
