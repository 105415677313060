<template>
  <header class="header-bg">
    <div class="header-container container">
      <a href="https://www.fhb.com/" data-test="header-home-link" id="header-home"
        ><img
          class="header-logo"
          srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
          src="@/assets/images/FHBColorLogo-2x.png"
          alt="First Hawaiian Bank home logo"
          title="FHB home"
      /></a>
    </div>
  </header>
</template>

<script>
export default {
  name: "the-header",
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.header-bg {
  background: $white;
}

.header-container {
  padding-left: 1em;
  padding-right: 1em;
  align-items: center;
  text-align: left;
  display: flex;
  gap: 1em;
  height: 55px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.3em 0 0.5em;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 80px;
    gap: 2em;
  }
}

.header-container img {
  display: block;
}

.title {
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  color: $black;

  @include media-breakpoint-down(md) {
    display: none;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.15rem;
  }
}

sup {
  font-size: 0.6rem;
  line-height: 1.5;
  white-space: nowrap;
}
</style>
