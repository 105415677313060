<template>
  <div>
    <img :src="mobileClould" alt="" />
  </div>
</template>

<script>
export default {
  name: "ProcessingMFA",
  data() {
    return {
      mobileClould: require("@/assets/images/loading.png"),
    }
  },
}
</script>

<style></style>
