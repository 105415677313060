<template>
  <div id="createuserpage" @click="unlock">
    <the-header></the-header>
    <HomepageBg></HomepageBg>
    <div class="form-container" id="create-user-page-display">
      <form @submit.prevent="validationPage">
        <!-- global error shows here -->
        <div id="personal-page-errors" />

        <div v-if="showErr" class="page-error-container" data-test="cu-page-err">
          <div class="page-error-icon-container">
            <img
              class="page-error-icon"
              srcset="
                @/assets/images/exclaimation-lg.png    1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              "
              src="@/assets/images/exclaimation-lg-2x.png"
              aria-hidden="true"
            />
          </div>
          <div role="alert" class="page-error-msg">
            You must complete or correct all required fields to proceed
          </div>
        </div>

        <div class="text-container">
          <span class="text-top"
            ><small class="topText">For your security we strongly recommend:</small></span
          >
          <ul>
            <li>
              <small class="topText" aria-required="true"
                >Create a unique Username and password that is complex, but that you can easily
                remember. Do not user your name as your User ID.</small
              >
            </li>
            <li>
              <small class="topText" aria-required="true"
                >Do not use the same Username or password that you use for any other
                websites.</small
              >
            </li>
            <li>
              <small class="topText" aria-required="true"
                >Keep First Hawaiian Bank up to date with your contact information.</small
              >
            </li>
          </ul>
        </div>
        <!-- Username input -->
        <div class="ll-item ll-lname">
          <div class="usernameLable">
            <label for="applicant-id" id="applicant-id"
              >Username <sup class="fhb-red">*</sup></label
            >
            <span
              class="additonalInfoBtn"
              @click="toggleUsernameContext"
              @keyup.space="toggleUsernameContext"
              tabindex="0"
            >
              <img
                v-if="!usernameContext"
                class="form-error-icon"
                srcset="@/assets/images/i.png 1x, @/assets/images/ix2.png 2x"
                src="@/assets/images/ix2.png"
                aria-hidden="true"
              />
              <img
                v-if="usernameContext"
                class="form-error-icon"
                srcset="@/assets/images/i-selected.png 1x, @/assets/images/i-selectedx2.png 2x"
                src="@/assets/images/i-selectedx2.png"
                aria-hidden="true"
              />
            </span>
          </div>
          <input
            :disabled="allowToPass ? true : false"
            aria-required="true"
            aria-labelledby="applicant-id"
            name="applicant-id"
            class="ll-textfield tf-lname"
            id="userId"
            data-test="cu-id"
            type="text"
            v-model="userID"
            @focus="showUsernameContext"
            @input="keydownUsername"
            :class="
              missingUserID || invalidUserID || !isUserNameAvailable
                ? 'll-textfield-error'
                : 'll-textfield'
            "
          />
          <span
            v-if="usernameContext || missingUserID || invalidUserID || !isUserNameAvailable"
            :class="
              missingUserID || invalidUserID || !isUserNameAvailable
                ? 'extendUsernamePop extend-info-error'
                : 'extendUsernamePop'
            "
          >
            <table class="extend-info-table">
              <tr>
                <th colspan="2">Username must meet these requirements:</th>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="usernameValidation.validLength"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>Between 6 and 32 characters in length.</td>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="usernameValidation.validChar"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>
                  Letters and numbers are allowed as well as these special characters: ~!@$%^*-_+
                </td>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="usernameValidation.validSpace"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>Spaces are not allowed.</td>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <div v-if="showLoader" class="loader"></div>
                  <img
                    v-if="isUserNameAvailable && userIDcheck"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else-if="userIDError"
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>Must be available for use.</td>
              </tr>
            </table>
          </span>
          <div v-if="checkUsernameErr" data-test="cu-id-err" class="form-error-msg">
            <img
              class="form-error-icon"
              srcset="
                @/assets/images/exclaimation-lg.png    1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              "
              src="@/assets/images/exclaimation-lg-2x.png"
            />
            <div role="alert" class="form-error-txt">
              Error has occured when checking availability. Please try again.
            </div>
          </div>
          <div
            v-if="!isUserNameAvailable && !missingUserID && !invalidUserID"
            data-test="cu-id-err"
            class="form-error-msg"
          >
            <img
              class="form-error-icon"
              srcset="
                @/assets/images/exclaimation-lg.png    1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              "
              src="@/assets/images/exclaimation-lg-2x.png"
            />
            <div role="alert" class="form-error-txt">This username is not available!</div>
          </div>
          <div v-if="!missingUserID && !invalidUserID" class="emptySpaceMultipleLine"></div>
        </div>
        <div>
          <div class="pwdLabel">
            <label for="new-password" id="new-password" class="new-password-label"
              >New Password <sup class="fhb-red">*</sup></label
            >
            <span
              class="additonalBtn"
              @click="toggleExtendContext"
              @keyup.space="toggleExtendContext"
              tabindex="0"
            >
              <img
                v-if="!extendContext"
                class="form-error-icon"
                srcset="@/assets/images/i.png 1x, @/assets/images/ix2.png 2x"
                src="@/assets/images/ix2.png"
                aria-hidden="true"
              />
              <img
                v-if="extendContext"
                class="form-error-icon"
                srcset="@/assets/images/i-selected.png 1x, @/assets/images/i-selectedx2.png 2x"
                src="@/assets/images/i-selectedx2.png"
                aria-hidden="true"
              />
            </span>
          </div>
          <!-- <New Password Input /> -->
          <input
            :disabled="allowToPass ? true : false"
            id="newPassword"
            data-test="cu-pw"
            type="password"
            v-model="newPassword"
            aria-required="true"
            aria-labelledby="new-password"
            name="new-password"
            @focus="showExtendContext"
            v-on:blur="validateNewPassword"
            @input="keydownNewPassword"
            :class="invalidNewPW ? 'll-textfield-error' : 'll-textfield'"
          />
          <div class="icon-validation">
            <img
              data-test="icon-valid"
              v-if="hidePw"
              class="pw-checkmark"
              @click="validateHidePwIcon"
              srcset="@/assets/images/hide.png 1x, @/assets/images/hide-2x.png 2x"
              src="@/assets/images/hide.png"
              alt="hide password checkmark icon"
            />
            <img
              data-test="icon-invalid"
              v-if="showPw"
              class="pw-checkmark"
              @click="validateShowPWIcon"
              srcset="@/assets/images/reveal.png 1x, @/assets/images/reveal-2x.png 2x"
              src="@/assets/images/reveal.png"
              alt="red x icon"
            />
          </div>
          <span
            v-if="extendContext || invalidNewPW"
            :class="invalidNewPW ? 'extendPop extend-info-error' : 'extendPop'"
          >
            <table class="extend-info-table">
              <tr>
                <th colspan="2">New Password must meet these requirements:</th>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="passwordValidation.validLength"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>Between 9 and 15 characters</td>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="passwordValidation.validNumber"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>At least 1 number</td>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="passwordValidation.validLower"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>At least 1 lower case</td>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="passwordValidation.validUpper"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>At least 1 upper case</td>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="passwordValidation.validSpecial"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>At least 1 special character</td>
              </tr>
              <tr>
                <td class="extend-info-table-icon">
                  <img
                    v-if="passwordValidation.validSpace"
                    srcset="
                      @/assets/images/green_check.png    1x,
                      @/assets/images/green_check_2x.png 2x
                    "
                    src="@/assets/images/green_check.png"
                    alt="checkmark icon"
                  />
                  <img
                    v-else
                    srcset="@/assets/images/red_check.png 1x, @/assets/images/red_check_2x.png 2x"
                    src="@/assets/images/red_check.png"
                    alt="red x icon"
                  />
                </td>
                <td>Contain no spaces</td>
              </tr>
            </table>
          </span>
          <!-- <div v-if="invalidNewPW" data-test="cu-pw-err" class="form-error-msg">
            <img class="form-error-icon" srcset="
       @/assets/images/exclaimation-lg.png 1x,
       @/assets/images/exclaimation-lg-2x.png 2x
        " src="@/assets/images/exclaimation-lg-2x.png" />
            <div role="alert" class="form-error-txt">
             New Password must be between 9 and 15 characters. Must contain at least 1 number, 1 lower
             case, 1 upper case and 1 special characters.
            </div>
        </div> -->
          <!-- <div v-if="!invalidNewPW" data-test="cu-pw-err" class="emptySpaceUL"></div> -->
          <div class="emptySpaceUL"></div>
        </div>

        <div class="ll-item">
          <label for="confirm-password" id="confirm-password" class="confirm-password-label"
            >Confirm New Password <sup class="fhb-red">*</sup></label
          >
          <!-- <Confirm password Input /> -->
          <input
            :disabled="allowToPass ? true : false"
            id="confirmPassword"
            data-test="cu-conf-pw"
            type="password"
            v-model="confirmPW"
            aria-required="true"
            aria-labelledby="confirm-password"
            name="confirm-password"
            v-on:blur="validateConfirmPassword"
            :class="missingConfirmPW || invalidConfirmPW ? 'll-textfield-error' : 'll-textfield'"
          />
          <!-- password visible button -->
          <div class="icon-validation">
            <img
              data-test="icon-valid"
              v-if="hideConfirmPw"
              class="pw-checkmark"
              @click="validateHideConfirmPwIcon"
              srcset="@/assets/images/hide.png 1x, @/assets/images/hide-2x.png 2x"
              src="@/assets/images/hide.png"
              alt="hide password checkmark icon"
            />
            <img
              data-test="icon-invalid"
              v-if="showConfirmPw"
              class="pw-checkmark"
              @click="validateShowConfirmPWIcon"
              srcset="@/assets/images/reveal.png 1x, @/assets/images/reveal-2x.png 2x"
              src="@/assets/images/reveal.png"
              alt="red x icon"
            />
          </div>
          <!-- <div class="icon-validation">
            <img data-test="cu-conf-pw-valid-icon" v-if="matchConfirmPWIcon" srcset="@/assets/images/check.png 1x, @/assets/images/check-2x.png 2x" src="@/assets/images/check.png" alt="checkmark icon" />
            <img data-test="cu-conf-pw-invalid-icon" v-if="unmatchConfirmPWIcon" srcset="@/assets/images/x-x.png 1x, @/assets/images/x-2x.png 2x" src="@/assets/images/x-x.png" alt="red x icon" />
        </div> -->

          <div v-if="missingConfirmPW" class="form-error-msg" data-test="cu-conf-pw-missing">
            <img
              class="form-error-icon"
              srcset="
                @/assets/images/exclaimation-lg.png    1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              "
              src="@/assets/images/exclaimation-lg-2x.png"
            />
            <div role="alert" class="form-error-txt">Please confirm password</div>
          </div>
          <div v-else-if="invalidConfirmPW" class="form-error-msg" data-test="cu-conf-pw-inv">
            <img
              class="form-error-icon"
              srcset="
                @/assets/images/exclaimation-lg.png    1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              "
              src="@/assets/images/exclaimation-lg-2x.png"
            />
            <div role="alert" class="form-error-txt">Your password does not match</div>
          </div>
          <div v-else-if="!invalidConfirmPW && !missingConfirmPW" class="emptySpace"></div>
        </div>

        <!-- Service Charge Account -->
        <div v-if="accInfo.length !== 0 && !teenAge && maskAccInfo[0] && !restrictedEnrollmentAcct">
          <label for="serviceChargeAccount" id="service-charge-Account" class="enrollOptionsLabel"
            >Select a Fee Account <sup class="fhb-red">*</sup></label
          >
          <p>
            Fees for optional services will be assessed to the selected account. See
            <a target="_blank" href="https://www.fhb.com/onlineterms"
              >FHB Online Terms & Conditions
              <span class="sr-only">(open in new tab)</span>
            </a>
            for details about any applicable fees.
          </p>
          <!-- <ul class="listContainer">
        <li class="listingAcc" v-for="item in accInfo[0]" :key="item.index">
          <input type="radio" aria-required="true" aria-labelledby="service-charge-Account" v-model="serviceChargeAcc"
            :value="item.account_number" data-test="cu-serv-charge-acct" />
          {{ item.account_number }} ({{ item.description }})
        </li>
         </ul> -->
          <div class="accDropdown">
            <select
              name="selected-Account"
              id="selected-Account"
              @change="choosedAccount"
              v-model="maskServiceChargeAcc"
            >
              <!-- <option value="accInfo[0][0]" selected >{{ accInfo[0][0].account_number }} ({{ accInfo[0][0].description }})</option> -->
              <option
                :value="item.maskAccNum"
                selected
                v-for="item in maskAccInfo[0]"
                :key="item.index"
              >
                {{ item.maskAccNum }} ({{ item.description }})
              </option>
              <!-- {{ '******'+ item.account_number.slice(6,10) }} ({{ item.description }})</option> -->
            </select>
          </div>
        </div>

        <!-- Enrollment Options -->
        <div
          class="enrollOptionContainer"
          v-if="accInfo.length !== 0 && !teenAge && maskAccInfo[0] && !restrictedEnrollmentAcct"
        >
          <label for="enrollOptions" id="enrollOptions" class="enrollOptionsLabel"
            >Enrollment Options <sup class="fhb-red">*</sup></label
          >
          <div class="enrollOptions">
            <label for="No-Bill-Pay" id="NoBillPay" class="selectEnrollOptions">
              <input
                type="radio"
                name="No-Bill-Pay"
                class="radioBtn"
                id="No-Bill-Pay"
                aria-required="true"
                aria-labelledby="No-Bill-Pay"
                aria-label="No-Bill-Pay"
                v-model="typeOfEnroll"
                value="Standard"
                checked="checked"
                data-test="cu-standard"
              />
              <span class="NoBillPay">Standard FHB Online Banking Services (Free)</span>
            </label>

            <label class="selectEnrollOptions" for="Bill-Pay" id="BillPay">
              <input
                type="radio"
                id="Bill-Pay"
                name="Bill-Pay"
                class="radioBtn"
                v-model="typeOfEnroll"
                aria-required="true"
                aria-label="Bill-Pay"
                aria-labelledby="Bill-Pay"
                value="Online"
                data-test="cu-bill-pay"
              />
              <span class="billPayText"
                >Online Bill Pay, includes standard services ($5.99/month. Free for Priority Banking
                customers and Pure Checking customers with a minimum of $500 in direct deposits per
                month.)</span
              >
            </label>

            <!-- <label class="selectEnrollOptions" for="Direct-Connect-with-Bill-Pay"
             id="DirectConnectwithBillPay">
             <input id="Direct-Connect-with-Bill-Pay" name="Direct-Connect-with-Bill-Pay" class="radioBtn" type="radio" v-model="typeOfEnroll" aria-required="true"
              aria-label="Direct-Connect-with-Bill-Pay" aria-labelledby="Direct-Connect-with-Bill-Pay"
              value="Direct Connect w/ Bill Pay" data-test="create-user-dc-bill-pay" />
             <span class="DirectWBillPay">Direct Connect, includes standard services & online bill pay ($9.95/month)</span>
            </label> -->
          </div>
        </div>

        <div class="submitBtn">
          <button
            type="submit"
            id="create-userid-submit-btn"
            :class="allowToPass ? 'processing' : null"
            @click="handleCTN"
            data-test="cu-submit"
          >
            <img
              v-if="submitBtn === 'PROCESSING...'"
              class="spinner"
              srcset="
                @/assets/images/yellow-spinner.png    1x,
                @/assets/images/yellow-spinner-2x.png 2x
              "
              src="@/assets/images/yellow-spinner.png"
              aria-hidden="true"
            />
            {{ submitBtn }}
          </button>
        </div>
      </form>
    </div>
    <the-footer></the-footer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import jQuery from "jquery"
import RetailApi from "@/app/retail-api"
import TheHeader from "@/components/header"
import TheFooter from "@/components/footer"
import HomepageBg from "@/components/homepagebg"
import Constans from "@/app/personal_constants"

// import Validator from "@/app/validation";
export default {
  name: "profile-info",
  components: { TheHeader, TheFooter, HomepageBg },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })

    if (window.history && window.history.pushState) {
      window.addEventListener("popstate", this.backChange, false)
    }
  },
  data() {
    return {
      passwordValidation: {
        validLength: false,
        validNumber: false,
        validLower: false,
        validUpper: false,
        validSpecial: false,
        validSpace: false,
      },

      usernameValidation: {
        validLength: false,
        validChar: false,
        validSpace: false,
      },

      usStates: Constans.States,
      submitBtn: "Enroll Now",
      errContainer: [],
      userIDcheck: false,
      userIDError: false,
      hidePw: true,
      showPw: false,
      hideConfirmPw: true,
      showConfirmPw: false,
      missingUserID: false,
      invalidUserID: false,
      invalidNewPW: false,
      invalidConfirmPW: false,
      missingConfirmPW: false,
      matchConfirmPWIcon: false,
      unmatchConfirmPWIcon: false,
      showErr: false,
      allowToPass: false,
      errUserID: false,
      errPwd: false,
      errConfirmPwd: false,
      isUserNameAvailable: true,
      isLoading: true,
      teenAge: false,
      showLoader: false,
      maskAccInfo: [],
      maskServiceChargeAcc: "",
      extendContext: false,
      usernameContext: false,
      encodedUserID: "",
      checkUsernameErr: false,
      restrictedEnrollmentAcct: false,
    }
  },
  created() {
    // enrollment options not selectable if enrolled account is restricted. set to ''
    let firstAcct = this.enrolled_account.replace(/\b(0+)/gi, "")
    if (firstAcct[0] == 6 && firstAcct[1] == 1) {
      // set both enrollment option and service charge account to be blank
      this.setTypeOfEnroll("")
      this.setServiceChargeAcc("")
      this.restrictedEnrollmentAcct = true
    }

    // if enrolled account is not restricted account
    if (!this.restrictedEnrollmentAcct) {
      // if service charge account exists, set as default
      if (this.accInfo[0] && this.accInfo[0][0]) {
        this.setServiceChargeAcc(this.accInfo[0][0].account_number)

        this.maskAccInfo = this.accInfo

        // mask account numbers of selectable charge accounts
        for (let i = 0; i < this.accInfo[0].length; i++) {
          this.maskAccInfo[0][i].maskAccNum =
            "******" + this.accInfo[0][i].account_number.slice(6, 10)
        }
        this.maskServiceChargeAcc = this.maskAccInfo[0][0].maskAccNum

        for (let i in this.maskAccInfo[0]) {
          if (this.serviceChargeAcc == this.maskAccInfo[0][i].maskAccNum) {
            this.serviceChargeAcc = this.maskAccInfo[0][i].account_number
            break
          }
        }

        // enrollment option if beginning with 61
        const str = this.accInfo[0][0].account_number
        let text = str.replace(/\b(0+)/gi, "")
        if (text[0] == 6 && text[1] == 1) {
          this.setTypeOfEnroll("")
        }
      }
    }

    // enrollment option shouldbe set to standard if minor
    if (this.applicantAge < 19 || !this.accInfo[0][0]) {
      this.setTypeOfEnroll("")
      this.setServiceChargeAcc("")
      this.teenAge = true
    } else {
      this.teenAge = false
    }
  },
  computed: {
    ...mapGetters("enrollhome", ["applicantID", "getOAO", "applicantAccNum"]),
    ...mapGetters("mfaData", ["token"]),
    ...mapGetters("createUserId", [
      "applicantUserID",
      "applicantNewPassword",
      "applicantConfirmPW",
      "applicantTypeOfEnroll",
      "applicantServiceChargeAcc",
    ]),
    ...mapGetters("mfaData", ["accInfo"]),
    ...mapGetters("enrollhome", ["applicantAge"]),
    userID: {
      get() {
        return this.applicantUserID
      },
      set(value) {
        this.setApplicantuserID(value)
      },
    },
    newPassword: {
      get() {
        return this.applicantNewPassword
      },
      set(value) {
        this.setNewPassword(value)
      },
    },
    confirmPW: {
      get() {
        return this.applicantConfirmPW
      },
      set(value) {
        this.setConfirmPW(value)
      },
    },
    typeOfEnroll: {
      get() {
        return this.applicantTypeOfEnroll
      },
      set(value) {
        this.setTypeOfEnroll(value)
      },
    },
    serviceChargeAcc: {
      get() {
        return this.applicantServiceChargeAcc
      },
      set(value) {
        this.setServiceChargeAcc(value)
      },
    },
    enrolled_account: {
      get() {
        return this.applicantAccNum
      },
    },
  },
  methods: {
    ...mapActions("createUserId", [
      "setApplicantuserID",
      "setNewPassword",
      "setConfirmPW",
      "setTypeOfEnroll",
      "setServiceChargeAcc",
    ]),
    unlock() {
      // this.extendContext = false
      // this.usernameContext = false
    },
    toggleUsernameContext(event) {
      event.stopPropagation()
      this.usernameContext = !this.usernameContext
    },
    showUsernameContext() {
      // username.focus
      this.usernameContext = true
      this.invalidUserID = false
      this.missingUserID = false
      this.keydownUsername()
    },
    toggleExtendContext(event) {
      event.stopPropagation()
      this.extendContext = !this.extendContext
    },
    showExtendContext() {
      // password.focus
      this.extendContext = true
      this.invalidNewPW = false
      this.keydownNewPassword()
    },

    // maskAcc(value){
    //  this.setServiceChargeAcc(value)
    //    value = '******'+ value.slice(6,10)
    // },

    backChange() {
      this.$router.push({ path: "/enroll" })
    },
    choosedAccount() {
      for (let i in this.maskAccInfo[0]) {
        if (this.maskServiceChargeAcc == this.maskAccInfo[0][i].maskAccNum) {
          this.serviceChargeAcc = this.maskAccInfo[0][i].account_number
          break
        }
      }
      const str = this.serviceChargeAcc
      let text = str.replace(/\b(0+)/gi, "")
      if (text[0] == 6 && text[1] == 1) {
        this.setTypeOfEnroll("")
        // document.getElementById("No-Bill-Pay").disabled = true;
        // document.getElementById("Bill-Pay").disabled = true;
        // document.getElementById("Direct-Connect-with-Bill-Pay").disabled = true;
      } else {
        this.setTypeOfEnroll("Standard")
      }
    },

    //check global err message for every single input field
    checkErrCount() {
      if (!this.errUserID && !this.errPwd && !this.errConfirmPwd && this.isUserNameAvailable) {
        this.showErr = false
      } else {
        this.showErr = true
      }
    },

    loadingUserName() {
      if (
        this.isUserNameAvailable &&
        this.applicantUserID.length > 5 &&
        this.applicantUserID.length < 33
      ) {
        this.showLoader = false
        this.userIDcheck = true
        this.userIDError = false
        this.showErr = false
      } else {
        this.showLoader = false
        this.userIDcheck = false
        this.userIDError = true
        this.showErr = true
      }
      if (this.missingUserID || this.invalidUserID || !this.isUserNameAvailable) {
        this.errUserID = true
      }
      if (!this.missingUserID && !this.invalidUserID && this.isUserNameAvailable) {
        this.errUserID = false
      }
    },

    // validata user id
    validateUserID() {
      this.userID = this.userID.trim()
      this.missingUserID = !this.userID
      const regex = /^([a-zA-Z0-9~!@$%^*\-_+]{6,32})$/
      if (!regex.test(this.userID)) {
        this.showLoader = false
        this.invalidUserID = true
        this.userIDcheck = false
        this.userIDError = true
      } else {
        this.invalidUserID = false
        this.userIDError = false
      }
      this.checkErrCount()
    },
    // validate show/hide icon
    validateHidePwIcon() {
      const x = document.getElementById("newPassword")
      if (x.type === "password") {
        x.type = "text"
        this.hidePw = false
        this.showPw = true
      } else {
        x.type = "password"
        this.hidePw = true
        this.showPw = false
      }
    },

    validateShowPWIcon() {
      const x = document.getElementById("newPassword")
      if (x.type === "text") {
        x.type = "password"
        this.hidePw = true
        this.showPw = false
      } else {
        this.hidePw = false
        this.showPw = true
        x.type = "password"
      }
    },

    validateHideConfirmPwIcon() {
      const x = document.getElementById("confirmPassword")
      if (x.type === "password") {
        x.type = "text"
        this.hideConfirmPw = false
        this.showConfirmPw = true
      } else {
        x.type = "password"
        this.hideConfirmPw = true
        this.showConfirmPw = false
      }
    },

    validateShowConfirmPWIcon() {
      const x = document.getElementById("confirmPassword")
      if (x.type === "text") {
        x.type = "password"
        this.hideConfirmPw = true
        this.showConfirmPw = false
      } else {
        this.hideConfirmPw = false
        this.showConfirmPw = true
        x.type = "password"
      }
    },
    keydownNewPassword() {
      this.passwordValidation.validLength =
        this.newPassword.length >= 9 && this.newPassword.length <= 15
      this.passwordValidation.validNumber = /\d/.test(this.newPassword)
      this.passwordValidation.validLower = /[a-z]/.test(this.newPassword)
      this.passwordValidation.validUpper = /[A-Z]/.test(this.newPassword)
      this.passwordValidation.validSpecial =
        this.newPassword != "" && !(this.newPassword.match(/^[a-zA-Z0-9\s]+$/) !== null)
      this.passwordValidation.validSpace = !this.newPassword.includes(" ")
      // validate new password
    },
    keydownUsername() {
      this.usernameValidation.validLength = this.userID.length >= 6 && this.userID.length <= 32
      this.usernameValidation.validChar = this.userID.match(/^[A-Za-z0-9~!@$%^*\-_+]*$/)
      this.usernameValidation.validSpace = !this.userID.includes(" ")
      if (
        this.usernameValidation.validChar &&
        this.usernameValidation.validLength &&
        this.usernameValidation.validSpace
      ) {
        this.checkUsername()
      }
      // validate new password
    },
    validateNewPassword() {
      const strongPassword = new RegExp(
        "^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[^\\w\\d\\s]).{9,15}$"
      )
      // if password passes regex test and if password does not have white spaces
      if (strongPassword.test(this.newPassword) && !this.newPassword.includes(" ")) {
        this.invalidNewPW = false
      } else {
        this.invalidNewPW = true
      }
      if (this.invalidNewPW) {
        this.errPwd = true
      } else {
        this.errPwd = false
      }
      this.checkErrCount()
    },
    // validate confirm password
    validateConfirmPassword() {
      this.missingConfirmPW = !this.confirmPW
      if (this.confirmPW !== this.newPassword) {
        this.invalidConfirmPW = true
        this.matchConfirmPWIcon = false
        this.unmatchConfirmPWIcon = true
      } else {
        this.invalidConfirmPW = false
        this.matchConfirmPWIcon = true
        this.unmatchConfirmPWIcon = false
      }
      if (this.missingConfirmPW || this.invalidConfirmPW) {
        this.errConfirmPwd = true
      }
      if (!this.missingConfirmPW && !this.invalidConfirmPW) {
        this.errConfirmPwd = false
      }
      this.checkErrCount()
    },
    validateConfirmPwIcon() {
      if (this.missingConfirmPW) {
        this.unmatchConfirmPWIcon = true
        this.matchConfirmPWIcon = false
      }
    },

    // check validation before submit
    checkBeforeSubmit() {
      if (
        this.missingUserID ||
        this.invalidUserID ||
        this.invalidNewPW ||
        this.invalidConfirmPW ||
        this.missingConfirmPW ||
        !this.isUserNameAvailable
      ) {
        this.allowToPass = false
      } else {
        this.allowToPass = true
      }
    },

    // check if to show global Error message
    errCnt() {
      if (!this.allowToPass) {
        this.showErr = true
      } else {
        this.showErr = false
      }
    },
    // handle continue button status
    handleCTN() {
      if (this.allowToPass) {
        this.submitBtn = "PROCESSING..."
      } else {
        this.submitBtn = "Enroll Now"
      }
    },

    // push err input to error container
    pushErrors() {
      if (this.missingUserID || this.invalidUserID || !this.isUserNameAvailable) {
        this.errContainer.push("userId")
      }
      if (this.invalidNewPW) {
        this.errContainer.push("newPassword")
      }
      if (this.missingConfirmPW || this.invalidConfirmPW) {
        this.errContainer.push("confirmPassword")
      }
    },

    async submitUserInfo(payload) {
      // Make submit call if information on form is valid
      if (this.allowToPass) {
        const resp = await RetailApi.submitUserInfo(payload, this.token)

        // if successful enrollment
        // if not from OAO continue with normal flow
        // if from OAO then send to different confirmation page that allows customer to go back to OAO flow
        if (resp.status === 200 && resp.data.message === "OK" && !this.getOAO)
          this.$router.push("/accountconfirmation")
        else if (resp.status === 200 && resp.data.message === "OK" && this.getOAO)
          this.$router.push("/accountconfirmation/oao")
        // if nopt successful enrollment, send user to different page
        else {
          this.$router.push("/visitbranch")
        }
      }
    },

    async checkUsername() {
      this.showLoader = true
      this.userIDcheck = false
      this.userIDError = false
      this.validateUserID()
      if (this.userIDError == false) {
        this.encodedUserID = encodeURIComponent(this.applicantUserID)

        const resp = await RetailApi.checkUserName(this.encodedUserID, this.applicantID, this.token)

        // testing
        // await new Promise(r => setTimeout(r, 1500));
        // const resp = {
        //   message: "OK",
        //   data: {
        //     response: {
        //       username_available: true,
        //     }
        //   },
        //   status: 200,
        // }

        if (resp && resp.status == 200) {
          if (resp && resp.message != "canceled") {
            this.validateUserID()
            if (resp.data.response.username_available === false) {
              this.isUserNameAvailable = false
              this.checkUsernameErr = false
              this.errUserID = false
              this.loadingUserName()
            } else if (
              resp.data.response.username_available === true &&
              this.userIDError == false
            ) {
              this.isUserNameAvailable = true
              this.errUserID = true
              this.checkUsernameErr = false
              this.loadingUserName()
            }
          }
        } else if (resp && resp.status !== 200) {
          this.userIDError = true
          this.showLoader = false
          this.checkUsernameErr = true
        }
      }
    },

    // validate error to scroll Top or focus on single input
    checkErr() {
      if (this.errContainer.length > 1 ? jQuery("html, body").animate({ scrollTop: 0 }) : null) {
        this.errContainer = []
      }
      if (
        this.errContainer.length === 1
          ? document.getElementById(this.errContainer[0]).focus()
          : null
      ) {
        this.errContainer = []
      } else if (this.errContainer.length === 0) {
        this.errContainer = []
      }
    },
    // final validation before submit
    validationPage() {
      this.validateUserID()
      this.validateConfirmPassword()
      this.validateNewPassword()
      this.validateConfirmPwIcon()
      this.checkBeforeSubmit()
      const payload = {
        id: this.applicantID,
        username: this.applicantUserID,
        password: this.applicantConfirmPW,
        enrollment_option: this.applicantTypeOfEnroll,
        service_charge_account: this.applicantServiceChargeAcc,
      }
      this.submitUserInfo(payload)
      this.errCnt()
      this.pushErrors()
      this.checkErr()
      this.handleCTN()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.enrollOptionContainer {
  padding-top: 20px;
}

.additonalInfoBtn {
  display: inline-block;
  margin-left: 10px;
  margin-top: 25px;
}

.additonalInfoBtn:hover {
  cursor: pointer;
}

.usernameLable {
  display: flex;
}

.extendPop {
  // border: 1px solid black;
  display: block;
  box-shadow: 0px 0px 5px 3px rgba(175, 175, 175, 0.25);
  border-radius: 6px;
  padding: 8px 15px;
  width: calc(80% - 2rem);
  margin-left: 40px;
  // position: absolute;
  z-index: 90;
  background-color: white;
  margin-top: 20px;
}

.extendUsernamePop {
  display: block;
  box-shadow: 0px 0px 5px 3px rgba(175, 175, 175, 0.25);
  border-radius: 6px;
  padding: 8px 15px;
  width: calc(92% - 2rem);
  margin-left: 40px;
  // position: absolute;
  z-index: 90;
  background-color: white;
  margin-top: 20px;
}

.additonalBtn {
  display: inline-block;
  margin-left: 10px;
  margin-top: 7px;
}

.additonalBtn:hover {
  cursor: pointer;
}

.pwdLabel {
  display: flex;
  // justify-items: center;
}

.form-error-msg {
  padding-bottom: 5px;
}

.topText {
  font-size: 15px;
}

.loader {
  display: inline-flex;
  justify-content: right;
  margin-right: 5px;
}

.loader::after {
  content: "";
  width: 24px;
  height: 24px;
  border: 4px solid #dddddd;
  border-top-color: rgb(56, 117, 95);
  border-radius: 50%;
  animation: loader 0.75s ease infinite;
}

@keyframes loader {
  to {
    transform: rotate(1turn);
  }
}

.page-error-container {
  margin-bottom: 20px;
}

.listingAcc {
  list-style-type: none;
  // height: 40px;
}

.accDropdown select {
  padding-left: 22px;
  margin-top: 0px;
  font-weight: 450;
  font-size: 18px;
  line-height: 150%;
  height: 39px;
  color: #333333;
}

.text-container {
  background-color: #fef5ee;
  padding: 18px;
}

.text-top {
  padding-left: 13px;
}

input {
  display: block;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  color: #333333;
  border: 1px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  padding: 19px;
  // margin-bottom: 30px;
}

input[type="radio"] {
  display: inline-block;
  width: 16px;
  margin: 0 10px 0 0;
  position: relative;
  top: 18px;
  box-shadow: none;
  margin-right: 15px;
  background-color: #fec443;
}

button {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 1em;
  display: inline-block;
  //font-family: "CircularXX TT";
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

/* a:hover,
a:active, */
button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.submitBtn {
  padding-top: 60px;
  padding-bottom: 30px;
  position: relative;
  text-align: center;
}

::placeholder {
  color: #bbbbbb;
  opacity: 1;
  /* Firefox */
}

.fhb-red {
  color: #a1252c;
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;

  // @include media-breakpoint-up(sm) {
  //   height: 56px;
  // }
}

.Id-textfield {
  background-color: $white;
  border: 1px solid $grayMid;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  background-image: url(@/assets/images/available.png);
  background-repeat: no-repeat;
  background-position: 500px 10px;
  padding-right: 0px;
}

.Id-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;

  // background-image: url(@/assets/images/x-2x.png);
  // background-repeat: no-repeat;
  // background-position: 500px 10px;
  // padding-right: 0px;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}

.userId-checkmark {
  // background-image: url(@/assets/images/x-2x.png);
  background-repeat: no-repeat;
  background-position: 500px 10px;
  padding-right: 0px;
  padding-top: 7px;
}

.icon-pw-validation {
  bottom: 2.5em;
  height: 0;
  padding-right: 25px;
  padding-top: 6.5px;
  position: relative;
  text-align: right;
  z-index: 2;

  img {
    height: auto;
    width: 24px;
  }

  @include media-breakpoint-down(sm) {
    bottom: 2em;
  }
}

input[type="radio"] {
  display: inline-block;
  width: 25px;
  margin: 0 10px 10px 0;
  position: relative;
  top: 15px;
  box-shadow: none;
  margin-right: 15px;
  // background-color: #fec443;
}

.enrollOptionsLabel {
  margin-bottom: 0%;
  // padding-top: 10px;
  // padding-bottom: 40px;
}

#enrollOptions {
  padding-bottom: 10px;
}

.enrollOptions {
  // display: flex;
  position: relative;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  // height: 150px;
}

.selectEnrollOptions {
  padding-top: 20px;
  position: relative;
  margin-top: 0px;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  display: flex;
  column-gap: 8px;
}

.selectEnrollOptions input {
  width: 25px;
  margin: 0 0 10px 0;
  position: relative;
  top: unset;
  box-shadow: none;
  //    margin-right: 15px;
  flex-basis: 20px;
  height: 25px;
}

.selectEnrollOptions span {
  flex: 1;
}

.emptySpace {
  position: relative;
  height: 21.6px;
}

.emptySpaceMultipleLine {
  position: relative;
  height: 43.16px;
}

.errorTextList {
  margin-top: auto;
  margin-bottom: auto;
}

.emptySpaceUL {
  position: relative;
  height: 43.16px;
}

.new-password-label {
  margin-top: 0;
}

.confirm-password-label {
  margin-top: 0;
  width: 30%px;
}

.streetNApt {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;

    .streeContiner {
      padding-right: 0px;
      width: 100%;
    }

    .unitContainer {
      margin-top: 20px;
      padding-right: 0px;
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;

    .streeContiner {
      padding-right: 0px;
      width: 100%;
    }

    .unitContainer {
      padding-right: 0px;
      margin-top: 20px;
      width: 100%;
    }
  }
}

.unitContainer {
  // margin-top: 11px;
  position: relative;
  width: 30%;
  padding-top: 4px;
}

.streeContiner {
  // margin-top: 7px;
  position: relative;
  width: 70%;
  padding-right: 24px;
}

.cityNStateNZip {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;

    .cityContiner {
      padding-right: 0px;
    }

    .stateContainer {
      padding-right: 0px;
      margin-top: 20px;
    }

    .zipCodeContainer {
      margin-top: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;

    .cityContiner {
      padding-right: 0px;
    }

    .stateContainer {
      padding-right: 0px;
      margin-top: 20px;
    }

    .zipCodeContainer {
      margin-top: 20px;
    }
  }
}

.stateContainer {
  width: 100%;
  padding-right: 20px;
  // width: 32%;
}

.zipCodeContainer {
  // width: 50%;
  width: 100%;
}

.cityContiner {
  // width: 43%;
  width: 100%;
  padding-right: 20px;
}

.NoBillPay:hover {
  cursor: pointer;
}

.billPayText:hover {
  cursor: pointer;
}

// .NoBillPay{
//  font-weight: 450;
//  font-size: 18px;
//  margin-top: 25px
// }
// .billPayText{
//  font-weight: 450;
//  font-size: 18px;
//  margin-top: 25px
// }
// .DirectWBillPay{
//  font-weight: 450;
//  font-size: 18px;
//  margin-top: 25px
// }
.radioBtn {
  width: 20px;
  top: unset;
  // padding-right: 20px;
  // height: 25px;
}

.radioBtn:hover {
  cursor: pointer;
}

.pw-checkmark {
  cursor: pointer;
}

select {
  display: block;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  color: #333333;
  border: 1px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  appearance: none;
  background-image: url("@/assets/images/caretDown-2x.png");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.processing {
  text-align: center;

  img {
    vertical-align: middle;
  }
}

.extend-info-table {
  width: 100%;
  th {
    padding-bottom: 1em;
  }
  td {
    padding-bottom: 0.5em;
  }
}

.extend-info-error {
  border: 1px solid $red;
  background-color: $redFaint;
}

.extend-info-table-icon {
  width: 10%;
  text-align: center;

  img {
    vertical-align: middle;
  }
}
</style>
