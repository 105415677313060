<template>
  <div id="invalid-acct-recovery-page">
    <the-header></the-header>
    <div class="bg-container">
      <div class="bg-orange">
        <h1 class="bg-text">Unlock my Account</h1>
        <div class="svg-container">
          <svg
            aria-hidden="true"
            height="100%"
            width="100%"
            preserveAspectRatio="none"
            viewBox="0 0 100 10"
            class="coh-inline-element"
          >
            <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
          </svg>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="form-container smaller-header" id="invalid-account-recovery">
      <div class="noti-container noti-spacing">
        <div class="overlay-info">
          <div class="headImgContainer">
            <img src="../../../assets/images/sadface.png" alt="unable to unlock account" />
          </div>
          <div class="bg-text1" id="main">
            <h3 class="bg-text-line">We are unable to recover your account at this time</h3>
            <p class="bg-text-line1">
              Your account may already be unlocked, or your one time url link may have expired.
              Please try again, or contact customer service for further assistance.
            </p>
            <div class="bg-text-line2">
              <p>
                Need help? Contact Customer Service
                <br />
                Daily 7am–7pm HST
                <br />
                (808) 643-4343 (Oahu)
                <br />
                (888) 643-4343 (Toll Free)
              </p>
            </div>
          </div>

          <div class="bottomLinksContainer">
            <a
              href=""
              @click="goToAccountRecovery"
              id="invalid-username-to-forgot"
              data-test="invalid-ar-forgot-link"
            >
              Return to account recovery form
            </a>
            <a :href="fhbOnline" id="invalid-username-to-login" data-test="invalid--login-link">
              Go to login
            </a>
          </div>
        </div>
      </div>
    </div>
    <the-footer />
  </div>
</template>

<script>
import jQuery from "jquery"
import TheHeader from "@/components/header"
import TheFooter from "@/components/footer"

export default {
  name: "invalidRecovery",
  components: { TheHeader, TheFooter },
  data() {
    return {
      fhbOnline: process.env.VUE_APP_FHB_ONLINE_URL,
    }
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    goToAccountRecovery() {
      this.$router.push({ name: "account-recovery" })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}

.noti-spacing.noti-container {
  padding-top: 40px;
}

img {
  padding-top: 30px;
  padding-bottom: 15px;
  height: auto;
  width: 93.5px;
}

.bg-text {
  padding-top: 70px;
  text-align: center;
}

.bg-text-line {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
}

.bg-text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-text-line1 {
  margin: 0 0 0 0;
  font-weight: 450;
  font-size: 15.5px;
}

.bg-text-line2 {
  margin: 0 0 0 0;
  font-weight: 450;
  font-size: 15.5px;
  text-align: center;
}

a:hover,
a:active {
  color: $redDark;
}

.bottomLinksContainer {
  display: grid;
  padding-top: 10px;
  justify-content: center;
  text-align: center;
  row-gap: 15px;
}
</style>
