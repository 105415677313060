<template>
  <div class="wallpaper">
    <div class="content" id="main">
      <div class="headerLog">
        <a href="https://www.fhb.com/"
          ><img
            srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
            src="@/assets/images/FHBColorLogo-2x.png"
            alt="First Hawaiian Bank home logo"
            title="FHB home"
        /></a>
      </div>
      <div class="email-logo">
        <img
          class="email-logo"
          srcset="@/assets/images/emailLogo.png 1x, @/assets/images/emailLogo-2x.png 2x"
          src="@/assets/images/emailLogo-2x.png"
          alt="open envelope with paper illustration"
        />
      </div>
      <div class="content-title">
        <h1>Enter verification Code</h1>
      </div>
      <div class="content-text" tabindex="0">
        <p class="content-text-p">To confirm your identity, we've sent a verification</p>
        <p class="content-text-p">code to the email address provided on your account.</p>
      </div>
      <!-- OPT input -->
      <div class="send-code">
        <div class="otpInput" @keydown.tab="handleKeydown">
          <OTPInput
            :digits="6"
            @update="getOTP"
            :error="isOTPValid === false"
            ariaDescribe="otp-error"
            dataTest="code-to-email-otp"
          />
        </div>
      </div>
      <div
        v-if="isOTPValid === false"
        class="error-message"
        data-test="code-to-email-err"
        id="otp-error"
      >
        <p role="alert" class="form-error-txt">
          Passcode incorrect. Please try again, or resend code.
        </p>
      </div>
      <div
        v-if="resendCodeStatus && resendCodeShows"
        class="resend-code-text"
        data-test="code-to-email-resent"
      >
        <b role="alert">Code has been resent!</b>
      </div>
      <div
        v-if="!resendCodeStatus && resendCodeShows"
        class="resend-code-text"
        data-test="code-to-email-not-resent"
      >
        <b role="alert"
          >The Secure Access Code could not be resent.<br />Please contact us at (888) 643-4343 for
          assistance.</b
        >
      </div>
      <div class="resend-code">
        <span>Didn't receive anything?</span>
        <button
          class="resend-code-btn"
          @click="resendCode"
          id="email-code-resend-btn"
          data-test="code-to-email-resend-btn"
        >
          Resend Code
        </button>
      </div>
      <div class="button-container">
        <button
          :class="!grayBtn ? 'submitBtn' : 'grayOutBtn'"
          type="submit"
          @click="verifyCode"
          id="email-code-verify-btn"
          data-test="code-to-email-verify"
        >
          VERIFY
        </button>
        <button class="back-Btn" @click="backRoute" id="send-code-to-email-btn">Back</button>
      </div>
      <Modal v-show="isModalVisible" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import RetailApi from "@/app/retail-api"
import { mapGetters, mapActions } from "vuex"
import Modal from "@/components/SomethingWrongModal.vue"
import jQuery from "jquery"
import OTPInput from "@/components/CustomInputs/OTPInput.vue"

export default {
  name: "SendCodeToEmail",
  components: { Modal, OTPInput },

  data() {
    return {
      mfaInput: "",
      isOTPValid: true,
      resendCodeStatus: false,
      resendCodeShows: false,
      accData: [],
      grayBtn: true,
      isModalVisible: false,
    }
  },

  computed: {
    ...mapGetters("enrollhome", [
      "applicantID",
      "applicantEmail",
      "applicantPhone",
      "getApplicantMobileFromTSYS",
      "getApplicantEmailFromTSYS",
      "applicantTypeOfAcc",
    ]),
    ...mapGetters("mfaData", [
      "loading",
      "token",
      "resendInfo",
      "mfaErrCounter",
      "sentOTPMethod",
      "finalEmailPayload",
      "finalPhonePayload",
    ]),
    load: {
      get() {
        return this.loading
      },
      set(value) {
        this.updateLoading(value)
      },
    },
    mfaErr: {
      get() {
        return this.mfaErrCounter
      },
      set(value) {
        this.updateMfaErrCounter(value)
      },
    },
    resendInfoStatus: {
      get() {
        return this.resendInfo
      },
      set(value) {
        this.updateResendInfo(value)
      },
    },
  },
  methods: {
    ...mapActions("mfaData", [
      "updateAccInfo",
      "updateResendInfo",
      "updateLoading",
      "updateToken",
      "updateMfaErrCounter",
      "updateSentOTPMethod",
      "updateEmailPayload",
      "updatePhonePayload",
    ]),
    handleOnComplete(value) {
      this.mfaInput = value
      if (value.length === 6) {
        this.grayBtn = false
      } else {
        this, (this.grayBtn = true)
      }
    },
    handleOnChange(value) {
      console.log("OTP changed: ", value)
      if (value.length === 6) {
        this.grayBtn = false
      } else {
        this, (this.grayBtn = true)
      }
    },

    handleKeydown() {
      document.getElementById("email-code-resend-btn").focus()
    },

    closeModal() {
      this.isModalVisible = false
    },

    // need to active after getting valid testing Acc
    async verifyOTPCode() {
      this.updateLoading(true)
      const payload = {
        id: this.applicantID,
        code: this.mfaInput,
      }
      const resp = await RetailApi.verifyOTPCode(payload)
      console.log("---MFA verify response ---")
      console.log(resp)
      if (resp.status === 200) {
        this.updateToken(resp.data.access_token)
        this.accData.push(resp.data.response)
        this.updateAccInfo(this.accData)
        this.updateLoading(false)
        // if customer is missing any core contact information or if they are a CC customer with missing core contact and missing a tsys contact info, collect the missing info
        if (
          ((!this.applicantEmail || this.applicantPhone.length == 0) &&
            !this.getApplicantEmailFromTSYS &&
            this.getApplicantMobileFromTSYS.length == 0) ||
            // CC that has no core and tsys email OR no core and tsys phone
          (this.applicantTypeOfAcc === "typeCreditCardNumber" &&
            (!this.applicantEmail && !this.getApplicantEmailFromTSYS) ||
             (this.applicantPhone.length == 0 && this.getApplicantMobileFromTSYS.length == 0))
        ) {
          this.$router.push("/profileinfo")
        } else {
          this.$router.push("/createuserid")
        }
      } else if (resp.status === 401) {
        this.updateLoading(false)
        this.updateMfaErrCounter(this.mfaErrCounter + 1)
        this.isOTPValid = false
      } else if (resp.status === 422) {
        this.updateLoading(false)
        this.isOTPValid = false
      } else {
        this.isModalVisible = true
      }
    },

    backRoute() {
      this.$router.push("/enhancedsecurity")
    },

    resendCode() {
      this.requestOTPCode()

      setTimeout(() => {
        if (this.resendInfoStatus === 200) {
          this.resendCodeStatus = true
          this.resendCodeShows = true
        } else {
          this.resendCodeStatus = false
          this.resendCodeShows = true
        }
      }, 1500)
    },

    verifyCode() {
      if (!this.grayBtn) {
        this.verifyOTPCode()
        this.resendCodeShows = false
      }
    },
    getOTP(otp, otpReady) {
      if (otpReady) {
        this.grayBtn = false
        this.mfaInput = otp
      } else {
        this.grayBtn = true
      }
    },

    async requestOTPCode() {
      this.updateLoading(true)
      const payload = {
        id: this.applicantID,
        methods: this.sentOTPMethod,
        email: encodeURIComponent(this.finalEmailPayload),
        phone: encodeURIComponent(this.finalPhonePayload),
      }

      const resp = await RetailApi.requestOTPCode(payload)
      console.log("--- MFA payload response ---")
      console.log(resp)
      // console.log(resp.status)
      this.updateResendInfo(resp.status)
      this.updateLoading(false)
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
    setTimeout(() => {
      if (this.resendInfo == 422) {
        this.resendCodeShows = true
      } else {
        this.resendCodeShows = false
      }
    }, 1500)
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.contactUs {
  padding-top: 8px;
  display: inline-block;
  color: #333333;
}
.resend-code-text {
  margin-top: 30px;
  text-align: center;
  font-weight: 450;
  font-size: 18px;
  font-style: bold;
  color: #811e24;
}

.form-error-icon {
  margin-top: 20px;
}

.error-message {
  text-align: center;
}

.wallpaper {
  background-image: url("~@/assets/images/orange@2x.svg");
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fef5ee;
}

.content {
  width: max(529px, 40%);
  // width: 100%;
  margin: 0 auto;
  /* width: 100%; */
  padding: 20px 30px 40px 30px;
  background: rgba(255, 255, 255, 0.8);
}

.resend-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 450;
}

.resend-code-btn {
  border: none;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 450;
  text-decoration: underline;
}

.resend-code-btn:hover {
  background-color: #ffffff;
  color: #a1252c;
  cursor: pointer;
}

.button-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#email-code-verify-btn {
  font-size: 0.8rem;
}

.submitBtn {
  height: 55px;
  max-width: 277px;
  width: 100%;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.headerLog {
  text-align: center;
  margin-top: 36px;
}

.email-logo {
  text-align: center;
  margin-top: 40px;
}

.email-logo img {
  height: auto;
  width: 100px;
}

.content-title {
  text-align: center;
  font-size: 28px;
}

.content-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-weight: 450;
  margin-bottom: 5px;
}

.content-text-p {
  margin-top: 5px;
  margin-bottom: 0px;
}

.send-code {
  margin-top: 40px;
  text-align: center;
  font-size: 18px;
  justify-content: center;
}

@include media-breakpoint-down(sm) {
  .content {
    width: 100%;
  }
  .otpInput {
    .otp-input {
      width: 34px;
      height: 44px;
      padding: 5px;
      margin: 0 10px;
    }
  }
}
@include media-breakpoint-down(md) {
  .otp-input {
    width: 34px;
    height: 44px;
    padding: 5px;
    margin: 0 10px;
  }
}

.otp-error-input {
  width: 54px;
  height: 64px;
  padding: 5px;
  margin: 0 10px;
  font-size: 30px;
  box-sizing: border-box;
  border-radius: 3px;
  color: #333333;
  border: 1.5px solid #a1252c;
  background: #ffffff;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
  text-align: center;
  background-color: #fbf2f2;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-error-input::-webkit-inner-spin-button,
.otp-error-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otpInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.back-Btn {
  border: none;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 450;
  cursor: pointer;
  padding-bottom: 10px;
  background-color: #ffffff;
}
#send-code-to-email-btn:hover {
  background-color: #ffffff;
  color: #811e24;
}
#email-code-resend-btn:hover {
  background-color: #ffffff;
  color: #811e24;
}
.grayOutBtn {
  height: 55px;
  max-width: 277px;
  width: 100%;
  position: relative;
  background-color: #e7e7e7;
  border: 1px solid white;
  color: #44444b;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;

  img {
    vertical-align: middle;
  }
}
</style>
