<template>
  <div id="main">
    <div class="headerLog">
      <a href="https://www.fhb.com/"
        ><img
          srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
          src="@/assets/images/FHBColorLogo-2x.png"
          alt="First Hawaiian Bank home logo"
          title="FHB home"
      /></a>
    </div>
    <div class="id-logo">
      <img
        srcset="@/assets/images/id-card.png 1x, @/assets/images/id-card-2x.png 2x"
        src="@/assets/images/FHBColorLogo-2x.png"
        alt="First Hawaiian Bank home logo"
        title="FHB home"
        aria-hidden="true"
      />
    </div>
    <div class="content-title">
      <h1>Enhanced security</h1>
    </div>
    <div class="content-text" data-test="enhanced-security-lname">
      <!-- only show this text when:
       1. non CC customer core email & phone 
       2. CC customer has core email & phone OR has tsys email & phone OR more than 1 tsys phone -->
      <p
        v-if="
          hasCorePhoneAndEmail() ||
          hasMoreThanOneCorePhone() ||
          hasCoreAndTSYSPhone() ||
          (isCreditCardCustomer() && (hasTSYSEmailAndPhone() || hasMoreThanOneTSYSPhone()))
        "
        class="content-text-p"
      >
        To confirm your identity, we will send a secure access code to the contact method of your
        choice.
      </p>
      <!-- only show this text when:
       1. non CC customer has no core email OR phone 
       2. CC customer has no core email or phone OR has no tsys email or phone -->
      <p
        v-if="
          hasCoreEmailButNoPhone() ||
          hasNoCoreEmailButOnePhone() ||
          (isCreditCardCustomer() && (hasTSYSEmailAndNoPhone() || hasNoTSYSEmailAndOnePhone()))
        "
      >
        To confirm your identity, we will send a secure access code to the contact method on file.
      </p>
    </div>
    <div class="send-code">
      <div
        v-if="
          hasMoreThanOneCorePhone() ||
          hasCorePhoneAndEmail() ||
          hasCoreAndTSYSPhone() ||
          (isCreditCardCustomer() && (hasTSYSEmailAndPhone() || hasMoreThanOneTSYSPhone()))
        "
      >
        <h3>
          <label for="select-delivery-method" id="select-delivery-method"
            >Where should we send your code?</label
          >
        </h3>
        <select
          v-model="deliverMethod"
          @change="DM"
          aria-required="true"
          aria-labelledby="select-delivery-method"
          name="select-delivery-method"
          data-test="enhanced-security-mfa-method"
        >
          <option value="" disabled selected>Select a delivery method</option>
          <option v-for="option in otpDeliveryMethods" :key="option.index" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div>
        <!-- show this text if customer only has one phone as contact info -->
        <h3
          v-if="
            (!isCreditCardCustomer() && hasNoCoreEmailButOnePhone()) ||
            (isCreditCardCustomer() &&
              !getApplicantEmailFromTSYS &&
              !applicantEmail &&
              ((getApplicantMobileFromTSYS.length == 1 && applicantPhone.length == 0) || (getApplicantMobileFromTSYS.length == 0 && applicantPhone.length == 1)))
          "
        >
          We'll send your code to the following telephone:
        </h3>
        <span
          v-if="
            (!isCreditCardCustomer() && hasNoCoreEmailButOnePhone()) ||
            (isCreditCardCustomer() &&
              !getApplicantEmailFromTSYS &&
              !applicantEmail &&
              ((getApplicantMobileFromTSYS.length == 1 && applicantPhone.length == 0) || (getApplicantMobileFromTSYS.length == 0 && applicantPhone.length == 1)))
          "
          class="content-text-p"
          >{{
            applicantPhone.length == 1 ? applicantPhone[0] : getApplicantMobileFromTSYS[0]
          }}</span
        >
        <!-- show this text if customer only has one email as contact info -->
        <h3
          v-if="
            hasCoreEmailButNoPhone() ||
            (isCreditCardCustomer() &&
              getApplicantEmailFromTSYS &&
              getApplicantMobileFromTSYS.length == 0)
          "
        >
          We'll send your code to the following email:
        </h3>
        <span
          v-if="
            hasCoreEmailButNoPhone() ||
            (isCreditCardCustomer() &&
              getApplicantEmailFromTSYS &&
              getApplicantMobileFromTSYS.length == 0)
          "
          class="content-text-p"
          >{{ applicantEmail ? applicantEmail : getApplicantEmailFromTSYS }}</span
        >
      </div>
    </div>
    <div class="button-container">
      <button
        v-if="
          applicantPhone.length == 0 &&
          !applicantEmail &&
          !getApplicantEmailFromTSYS &&
          getApplicantMobileFromTSYS.length == 0
        "
        :disabled="!deliverMethod"
        :class="deliverMethod ? 'submitBtn' : 'grayOutBtn'"
        type="submit"
        @click="sendCode"
        id="enhanced-security-send-code-btn"
        data-test="enhanced-security-send-btn"
      >
        SEND CODE
      </button>
      <button
        v-if="
          applicantPhone.length > 0 ||
          applicantEmail ||
          getApplicantEmailFromTSYS ||
          getApplicantMobileFromTSYS.length > 0
        "
        class="submitBtn"
        type="submit"
        @click="sendCode"
        id="enhanced-security-send-code-btn"
        data-test="enhanced-security-send-btn"
      >
        SEND CODE
      </button>
    </div>
  </div>
</template>

<script>
import RetailApi from "@/app/retail-api"
import { mapGetters, mapActions } from "vuex"
import jQuery from "jquery"

export default {
  name: "MFAEnhancedSecuity",
  data() {
    return {
      deliverMethod: "",
      emailPayload: "",
      mobilePayload: "",
    }
  },
  created() {
    if (this.applicantPhone.length == 1 && this.getApplicantMobileFromTSYS.length == 0 && !this.applicantEmail) {
      this.deliverMethod = this.applicantPhone[0]
    }
    if (this.applicantPhone.length == 0 && this.getApplicantMobileFromTSYS.length == 0 && this.applicantEmail) {
      this.deliverMethod = this.applicantEmail
    }
    // If the customer has a tsys email but no tsys & core phone, assign the deliver method to the email
    if (
      this.applicantTypeOfAcc === "typeCreditCardNumber" &&
      this.getApplicantEmailFromTSYS &&
      this.getApplicantMobileFromTSYS.length == 0 &&
      this.applicantPhone.length == 0 
    ) {
      this.deliverMethod = this.getApplicantEmailFromTSYS
    }
    // If the customer has no tsys email and only tsys/core phone number from tsys, assign that phone number as the deliver method
    if (
      this.applicantTypeOfAcc === "typeCreditCardNumber" &&
      !this.getApplicantEmailFromTSYS 
    ) {
      if (
        this.getApplicantMobileFromTSYS.length == 1 && 
        this.applicantPhone.length == 0
      ) {
          this.deliverMethod = this.getApplicantMobileFromTSYS[0]
        } else if (
          this.applicantPhone.length == 1 && 
          this.getApplicantMobileFromTSYS.length == 0
        ) {
          this.deliverMethod = this.applicantPhone[0]
        }
    }
    //If a customer has more than one contact method, itll go to a different flow where they get to choose what contact method they want to send the code to
  },
  computed: {
    ...mapGetters("enrollhome", [
      "applicantLastName",
      "applicantID",
      "applicantPhone",
      "applicantEmail",
      "getApplicantMobileFromTSYS",
      "getApplicantEmailFromTSYS",
      "isCypressTest",
      "applicantTypeOfAcc",
    ]),
    ...mapGetters("mfaData", [
      "loading",
      "methodsStatus",
      "resendInfo",
      "sentOTPMethod",
      "finalEmailPayload",
      "finalPhonePayload",
    ]),
    ...mapGetters("profileInfo", ["applicantSubmitEmail", "applicantPhoneNum"]),

    load: {
      get() {
        return this.loading
      },
      set(value) {
        this.updateLoading(value)
      },
    },

    getFinalEmailPayload: {
      get() {
        return this.finalEmailPayload
      },
      set(value) {
        this.updateEmailPayload(value)
      },
    },
    getFinalPhonePayload: {
      get() {
        return this.finalPhonePayload
      },
      set(value) {
        this.updatePhonePayload(value)
      },
    },

    otpDeliveryMethods: {
      get() {
        let methods = []
        if (this.applicantPhone) {
          methods.push(...this.applicantPhone)
        }
        if (this.applicantEmail) {
          methods.push(this.applicantEmail)
        }
        if (this.getApplicantEmailFromTSYS) {
          methods.push(this.getApplicantEmailFromTSYS)
        }
        if (this.getApplicantMobileFromTSYS) {
          methods.push(...this.getApplicantMobileFromTSYS)
        }
        return methods
      },
    },
    resendInfo: {
      get() {
        return this.resendInfo
      },
      set(value) {
        this.updateResendInfo(value)
      },
    },
  },

  methods: {
    ...mapActions("mfaData", [
      "updateLoading",
      "updateResendInfo",
      "updateSentOTPMethod",
      "updateEmailPayload",
      "updatePhonePayload",
    ]),
    DM() {
      console.log("Selected deliver method: ", this.deliverMethod)
    },

    async requestOTPCode() {
      this.updateLoading(true)

      // take the selected delivery method and pass it to BE
      if (this.deliverMethod.includes("@")) {
        this.updateEmailPayload(this.deliverMethod)
        this.updatePhonePayload("")
      } else {
        this.updateEmailPayload("")
        this.updatePhonePayload(this.deliverMethod)
      }
      var formattedPhone = this.getFinalPhonePayload.replace('+1', '').replace(/[^+\d]+/g, '')
      const payload = {
        id: this.applicantID,
        methods: this.sentOTPMethod,
        email: encodeURIComponent(this.getFinalEmailPayload),
        phone: encodeURIComponent(formattedPhone)
      }
      const resp = await RetailApi.requestOTPCode(payload)
      if (this.isCypressTest) {
        this.$router.push("/createuserid")
        return
      }
      this.updateResendInfo(resp.status)
      this.updateLoading(false)
    },

    sendCode() {
      if (this.deliverMethod.includes("@")) {
        this.updateSentOTPMethod("email")
        this.$router.push("/sendcodetoemail")
      }
      if (!this.deliverMethod.includes("@")) {
        this.updateSentOTPMethod("mobile")
        this.$router.push("/sendcodetophone")
      }
      if (!this.deliverMethod) {
        console.log("DO NOT PASS")
      }
      this.requestOTPCode()
    },

    hasCorePhoneAndEmail() {
      return this.applicantPhone.length > 0 && this.applicantEmail
    },

    hasMoreThanOneCorePhone() {
      return this.applicantPhone.length > 1
    },

    isCreditCardCustomer() {
      return this.applicantTypeOfAcc === "typeCreditCardNumber"
    },
    // check for either TSYS and core phone
    hasTSYSEmailAndPhone(){
      return this.getApplicantEmailFromTSYS && (this.getApplicantMobileFromTSYS.length > 0 || this.applicantPhone.length > 0)
    },

    hasMoreThanOneTSYSPhone() {
      return this.getApplicantMobileFromTSYS.length > 1
    },

    hasCoreEmailButNoPhone(){
      return (this.applicantPhone.length == 0 && this.getApplicantMobileFromTSYS.length == 0 && this.applicantEmail) 
    },

    hasNoCoreEmailButOnePhone(){
      return (((this.applicantPhone.length == 1 && this.getApplicantMobileFromTSYS.length == 0) || (this.applicantPhone.length == 0 && this.getApplicantMobileFromTSYS.length == 1)) && !this.applicantEmail)
    },

    hasTSYSEmailAndNoPhone(){
      return (this.getApplicantEmailFromTSYS && this.getApplicantMobileFromTSYS.length == 0 && this.applicantPhone.length == 0)
    },
    // check for either TSYS and core phone
    hasNoTSYSEmailAndOnePhone(){
      return !this.getApplicantEmailFromTSYS && ((this.applicantPhone.length == 1 && this.getApplicantMobileFromTSYS.length == 0) || (this.applicantPhone.length == 0 && this.getApplicantMobileFromTSYS.length == 1))
    },

    hasCoreAndTSYSPhone(){
      return this.applicantPhone.length > 0 && this.getApplicantMobileFromTSYS.length > 0
    }
  },

  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
}
</script>

<style scoped>
#main {
  padding-left: 20px;
  padding-right: 20px;
}
#enhanced-security-send-code-btn {
  font-size: 0.8rem;
}
.button-container {
  text-align: center;
}

.submitBtn {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

.grayOutBtn {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #e7e7e7;
  border: 1px solid white;
  color: #44444b;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

.grayOutBtn:hover {
  cursor: default !important;
  background-color: #e7e7e7 !important;
}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.headerLog {
  text-align: center;
  margin-top: 36px;
}

.id-logo {
  text-align: center;
  margin-top: 40px;
}

.content-title {
  text-align: center;
  font-size: 28px;
}

.content-text {
  text-align: center;
  font-size: 18px;
  font-weight: 450;
}

.content-text-p {
  margin: 0px;
}

.send-code {
  /* margin-top: 40px; */
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
}

select {
  width: 328px;
  height: 56px;
  font-size: 18px;
  font-weight: 450;
  padding-left: 21px;
  padding-right: 21px;
  appearance: none;
  background-image: url("@/assets/images/caretDown-2x.png");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}
</style>
