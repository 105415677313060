export default {
  namespaced: true,
  state: {
    applicant: {
      userID: "",
      newPassword: "",
      confirmPW: "",
      typeOfEnroll: "Standard",
      serviceChargeAcc: "",
      streetAddress: "",
      unitNum: "",
      city: "",
      state: "",
      zipCode: "",
    },
  },
  actions: {
    setApplicantuserID({ commit }, email) {
      commit("setApplicantuserID", email)
    },
    setNewPassword({ commit }, phoneNum) {
      commit("setNewPassword", phoneNum)
    },
    setConfirmPW({ commit }, confirmPW) {
      commit("setConfirmPW", confirmPW)
    },
    setTypeOfEnroll({ commit }, typeOfEnroll) {
      commit("setTypeOfEnroll", typeOfEnroll)
    },
    setStreetAddress({ commit }, streetAddress) {
      commit("setStreetAddress", streetAddress)
    },
    setUnitNum({ commit }, unitNum) {
      commit("setUnitNum", unitNum)
    },
    setCity({ commit }, city) {
      commit("setCity", city)
    },
    setState({ commit }, state) {
      commit("setState", state)
    },
    setZipCode({ commit }, zipCode) {
      commit("setZipCode", zipCode)
    },
    setServiceChargeAcc({ commit }, serviceChargeAcc) {
      commit("setServiceChargeAcc", serviceChargeAcc)
    },
  },
  mutations: {
    setApplicantuserID(state, value) {
      state.applicant.userID = value
    },
    setNewPassword(state, value) {
      state.applicant.newPassword = value
    },
    setConfirmPW(state, value) {
      state.applicant.confirmPW = value
    },
    setTypeOfEnroll(state, value) {
      state.applicant.typeOfEnroll = value
    },
    setStreetAddress(state, value) {
      state.applicant.streetAddress = value
    },
    setUnitNum(state, value) {
      state.applicant.unitNum = value
    },
    setCity(state, value) {
      state.applicant.city = value
    },
    setState(state, value) {
      state.applicant.state = value
    },
    setZipCode(state, value) {
      state.applicant.zipCode = value
    },
    setServiceChargeAcc(state, value) {
      state.applicant.serviceChargeAcc = value
    },
  },
  getters: {
    applicantUserID: state => state.applicant.userID,
    applicantNewPassword: state => state.applicant.newPassword,
    applicantConfirmPW: state => state.applicant.confirmPW,
    applicantTypeOfEnroll: state => state.applicant.typeOfEnroll,
    applicantStreetAddress: state => state.applicant.streetAddress,
    applicantUnitNum: state => state.applicant.unitNum,
    applicantCity: state => state.applicant.city,
    applicantState: state => state.applicant.state,
    applicantZipCode: state => state.applicant.zipCode,
    applicantServiceChargeAcc: state => state.applicant.serviceChargeAcc,
  },
}
